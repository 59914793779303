import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import DSAFeedback from '../../Models/DSAFeedback';
import Module from '../../Models/Module';
import RepairAction from '../../Models/RepairAction';
import EFC from '../../Models/EFC';
import db from '../../index-db';
import SectionHeading from '../../components/SectionHeading';
import FeedbackCard from './FeedbackCard';
import { TIcon } from '../../types';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import moment from 'moment-timezone';

interface Props {
  feedback: DSAFeedback;
}

enum FeedbackThumbdownCategories {
  POSITIVEFEEDBACKNOACTION = 'Positive Feedback, no additional action',
  NEGATIVEFEEDBACKNOACTION = 'Negative Feedback, no additional action',
  DUPLICATEFEEDBACKENTRY = 'Duplicate Feedback Entry',
  SERVICECALLISSUE = 'Service Call Issue',
  USERERRORNOACTION = 'User Error, no additional action',
}

function DetailFeedback(props: Props) {
  const { feedback } = props;
  const { t } = useTranslation();
  const [feedbackModule, setfeedbackModule] = useState<Module>();
  const [feedbackRA, setfeedbacRA] = useState<RepairAction>();
  const [feedbackEFC, setfeedbackEFC] = useState<EFC>();

  const GetFeedbackType = (data: DSAFeedback): string => {
    if (data.raId) {
      return 'Repair Feedback';
    } else if (data.efcCodeId) {
      return 'EFC Feedback';
    } else {
      return 'General Feedback';
    }
  };

  const feedbackType = GetFeedbackType(feedback);

  useEffect(() => {
    (async () => {
      const module = await db.modules.get(feedback.odysseyModuleId);
      setfeedbackModule(module);

      if (feedback.raId) {
        const repairAction = await db.raactions.get(feedback.raId);
        setfeedbacRA(repairAction);
      }

      if (feedback.efcCodeId) {
        const efc = await db.efcs.get(feedback.efcCodeId);
        setfeedbackEFC(efc);
      }
    })();
  }, []);
  let feedbackOutcomeIcon: TIcon;

  switch (feedback.feedbackOutcomeText) {
    case FeedbackThumbdownCategories.DUPLICATEFEEDBACKENTRY.toString():
    case FeedbackThumbdownCategories.NEGATIVEFEEDBACKNOACTION.toString():
    case FeedbackThumbdownCategories.POSITIVEFEEDBACKNOACTION.toString():
    case FeedbackThumbdownCategories.SERVICECALLISSUE.toString():
    case FeedbackThumbdownCategories.USERERRORNOACTION.toString():
      feedbackOutcomeIcon = ThumbDownIcon;
      break;
    default:
      feedbackOutcomeIcon = ThumbUpIcon;
  }

  const submitTime = `${feedback.submitTime}Z`;

  const fieldFeedbackRequestTime = feedback.fieldFeedbackRequestTime
    ? moment.tz(feedback.fieldFeedbackRequestTime, 'EST').utc().format()
    : '';

  const level4ReviewTime = feedback.level4ReviewTime
    ? moment.tz(feedback.level4ReviewTime, 'EST').utc().format()
    : '';

  const inProcessByLevel4ReviewTime = feedback.inProcessByLevel4ReviewTime
    ? moment.tz(feedback.inProcessByLevel4ReviewTime, 'EST').utc().format()
    : '';

  let reviewTime = '';
  if (feedback.reviewTime) {
    if (feedback.reviewer === 'autoreview') {
      reviewTime = `${feedback.reviewTime}Z`;
    } else {
      reviewTime = moment.tz(feedback.reviewTime, 'EST').utc().format();
    }
  }

  return (
    <div className="ml-2 mr-2">
      <div className="mb-2 items-center justify-between">
        <SectionHeading title={`${t(feedbackType)}`} withMargins={false} />
      </div>
      {feedbackModule && (
        <div className="mb-2 flex items-start justify-between px-1">
          <div className={'w-1/3 text-left text-base font-bold'}>
            {t('Module Name')}:
          </div>
          <div className={'w-2/3 text-left text-base'}>
            {feedbackModule?.ModuleName}
          </div>
        </div>
      )}
      {feedbackEFC && (
        <div className="mb-2 flex items-start justify-between px-1">
          <div className={'w-1/3 text-left text-base font-bold'}>
            {t('EFC Code')}:
          </div>
          <div className={'w-2/3 text-left text-base'}>
            {feedbackEFC.EfcCodeStr}
          </div>
        </div>
      )}
      {feedbackRA && (
        <div className="mb-2 flex items-start justify-between px-1">
          <div className={'w-1/3 text-left text-base font-bold'}>
            {t('Repair Action')}:
          </div>
          <div className={'w-2/3 text-left text-base'}>
            {feedbackRA.Description}
          </div>
        </div>
      )}
      <div className="mt-2">
        <FeedbackCard
          title={t('Your Feedback')}
          description={feedback.comments}
          reviewTime={submitTime}
        />
        {feedback.fieldFeedbackRequestTime && (
          <FeedbackCard
            title={t('Field Feedback Requested')}
            description={feedback.fieldFeedbackRequestComments}
            reviewTime={fieldFeedbackRequestTime}
          />
        )}
        {feedback.level4ReviewTime && (
          <FeedbackCard
            title={t('Level 4 to Review')}
            description={feedback.level4ReviewComments}
            reviewTime={level4ReviewTime}
          />
        )}
        {feedback.inProcessByLevel4ReviewTime && (
          <FeedbackCard
            title={t('In Process by Level 4')}
            description={feedback.inProcessByLevel4ReviewComments}
            reviewTime={inProcessByLevel4ReviewTime}
          />
        )}
        {feedback.reviewTime && (
          <FeedbackCard
            title={t('Processed')}
            description={feedback.reviewComments}
            reviewTime={reviewTime}
            feedbackOutcomeText={feedback.feedbackOutcomeText}
            feedbackOutcomeIcon={feedbackOutcomeIcon}
          />
        )}
      </div>
    </div>
  );
}

export default DetailFeedback;

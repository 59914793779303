import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { useLiveQuery } from 'dexie-react-hooks';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { SwipeableList, SwipeableListItem, Type } from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import ScrollToTopButton from '../../components/ScrollToTopButton';
import { SelectChangeEvent } from '@mui/material/Select';
import db from '../../index-db';
import { IDType } from '../../Models/Enumerations';
import RepairAction from '../../Models/RepairAction';
import { usePage } from '../../PageProvider';
import useLogging from '../../hooks/useLogging';
import LogParams from '../../Models/LogParams';
import RepairActionCard from './RepairActionCard';
import RepairActionCardLeadingActions from './RepairActionCardLeadingActions';
import RepairActionCardTrailingActions from './RepairActionCardTrailingActions';
import CustomRepairActionCard from './CustomRepairActionCard';
import FeedbackScreen from '../Feedback/FeedbackScreen';
import EfcFeedbackDialog from '../Feedback/EfcFeedbackDialog';
import CustomRepairFeedbackScreen from '../Feedback/CustomRepairActionScreen';
import useAddToRecentlyViewed from '../../hooks/useAddToRecentlyViewed';
import useAddToSavedItems from '../../hooks/useAddToSavedItems';
import PageNotFound from '../../PageNotFound';
import Image from '../../components/Image';
import { useToast } from '../../components/toast/ToastProvider';
import { LogType } from '../../enums/LogType';
import Dropdown from '../../components/EndpointDropdown';
import { useApi } from '../../acfs-apis/dwar-api-provider';
import Task from '../../Models/Task';
import { LAST_ACCESSED_ENDPOINT } from '../../constants';
import useUserEndpointsDropdown from '../../hooks/useUserEndpointsDropdown';

interface IRepairActionCardData {
  repairActionRecords: RepairAction[];
  efcProbableCause: string;
}

export interface IEFCFeedbackData {
  efcHeading: string;
  efcId: number;
  efcDesciption: string;
  moduleId: number;
}
function EFCPage() {
  const api = useApi();

  const [selectedEndpoint, setSelectedEndpoint] = useState('');
  const params = useParams();
  const efcState = useLocation().state;
  const { t } = useTranslation();
  const logging = useLogging();
  const { pushToast } = useToast();
  const addToRecentlyViewed = useAddToRecentlyViewed();
  const addToSavedItems = useAddToSavedItems();
  const [isFeedbackOpen, setFeedbackOpen] = useState(false);
  const [isCustomRepairFeedbackOpen, setCustomRepairFeedbackOpen] =
    useState(false);
  const [isWrongURL, setIsWrongURL] = useState(false);
  const [selectedSerialNumber, setSelectedSerialNumber] = useState('');
  const efcId = Number(params.idGeneric);
  const fullFeedback = Boolean(efcState?.fullFeedback) ?? false;
  const displayTime = undefined;

  const lastAccessedEndpoint =
    window.localStorage.getItem(LAST_ACCESSED_ENDPOINT) ?? '';
  const [userEndpoints, populateEndpointOptions] = useUserEndpointsDropdown();
  const efc = useLiveQuery(async () => {
    return db.efcs.get(efcId);
  }, [efcId]);

  const isSaved = useLiveQuery(async () => {
    const efcRecord = await db.savedItems
      .where({
        IdGeneric: efcId,
        IdType: IDType.EFC,
      })
      .toArray();
    return efcRecord.length > 0;
  }, [efcId]);

  const getEFCHeading = useCallback(() => {
    return `EFC ${efc?.EfcCodeStr}`;
  }, [efc?.EfcCodeStr]);

  usePage(
    () => ({
      getTitle: getEFCHeading,
      belongsToNavBarItem: 'Menu',
      rightButtons: [
        { icon: CommentOutlinedIcon, onClick: () => setFeedbackOpen(true) },
        {
          icon: isSaved ? BookmarkIcon : BookmarkBorderOutlinedIcon,
          iconColor: isSaved ? 'text-cobalt' : undefined,
          onClick: () => {
            if (efc) {
              addToSavedItems(efcId, IDType.EFC, getEFCHeading(), pushToast);
            }
          },
        },
      ],
      showBackButton: true,
    }),
    [getEFCHeading, isSaved, efc, addToSavedItems, efcId, pushToast]
  );

  function compareDifficulty(a: string | null, b: string | null) {
    if (a === b) return 0;
    else if (a === null || a === 'H' || b === 'L') return 1;
    else if (b === null || a === 'L' || b === 'H') return -1;
  }
  useEffect(() => {
    (async () => {
      const efcRecord = await db.efcs.get(efcId);
      if (efcRecord) {
        setIsWrongURL(false);
        addToRecentlyViewed(efcId, IDType.EFC, '');
      } else {
        setIsWrongURL(true);
      }
    })();
  }, [efcId]);

  const repairActionCardData = useLiveQuery(async () => {
    const efcRecord = await db.efcs.get(efcId);
    if (efcRecord) {
      const repairActionCardData = {} as IRepairActionCardData;
      repairActionCardData.efcProbableCause = efcRecord?.ProbableCause;
      if (efcRecord.RepairActionData != null) {
        repairActionCardData.repairActionRecords = await db.raactions
          .where('Id')
          .anyOf(efcRecord.RepairActionData.map((item) => item.RepairActionId))
          .toArray();
        const repairRatings: Record<number, number> = {};
        efcRecord.RepairActionData.forEach((result) => {
          repairRatings[result.RepairActionId] = result.RecommendRating;
        });
        repairActionCardData.repairActionRecords.forEach(function (
          record,
          index,
          repairActionArray
        ) {
          repairActionArray[index].Rating = repairRatings[record.Id];
        });
        repairActionCardData.repairActionRecords.sort(function (a, b) {
          return (
            b.Rating - a.Rating ||
            compareDifficulty(a.RepairComplexity, b.RepairComplexity) ||
            a.TimeToFix - b.TimeToFix ||
            a.Id - b.Id
          );
        });
      }
      return repairActionCardData;
    }
  }, [efcId]);

  const fetchOpenServiceTasks = useCallback(async (): Promise<Task[]> => {
    return (await api.fetchJson(
      '/dwar/api/almanac/ServiceTask/getOpenServiceTasks'
    )) as Task[];
  }, [api]);

  const setEndpointDefaultValue = useCallback(() => {
    if (lastAccessedEndpoint !== '') {
      setSelectedEndpoint(lastAccessedEndpoint);
    } else {
      setSelectedEndpoint(t('No Endpoint'));
    }
  }, [lastAccessedEndpoint, t]);

  useEffect(() => {
    (async () => {
      try {
        const efcRecord = await db.efcs.get(efcId);
        if (efcRecord?.EfcCodeStr) {
          const logParams: LogParams[] = [
            {
              key: LogType.Page,
              value: LogType.EFC,
            },
            {
              key: LogType.EfcCode,
              value: efcRecord?.EfcCodeStr,
            },
            {
              key: LogType.ExternalSource,
              value: efcState?.externalSource
                ? efcState.externalSource.toString()
                : '',
            },
          ];
          try {
            let tasks = await fetchOpenServiceTasks();
            setEndpointDefaultValue();
            await populateEndpointOptions(tasks);
            tasks = tasks.filter(
              (task) =>
                task.deviceSerialNumber !== null &&
                task.deviceSerialNumber !== '' &&
                task.deviceSerialNumber !== 'N/A'
            );
            await db.tasks.clear();
            await db.tasks.bulkPut(tasks);

            logging('info', `tasks fetched ${tasks.length}`, '', false, null);
            logging('info', '', '', false, logParams, true);
          } catch (error) {
            const tasks = await db.tasks.toArray();
            setEndpointDefaultValue();
            await populateEndpointOptions(tasks);
            logging('info', '', '', false, logParams, true);
          }
        }
      } catch {
        console.log('Error fetching EFC');
      }
    })();
  }, [
    efcId,
    efcState?.externalSource,
    fetchOpenServiceTasks,
    logging,
    populateEndpointOptions,
    setEndpointDefaultValue,
  ]);

  useEffect(() => {
    const endpointSelected = userEndpoints.find(
      (endpoint) => endpoint.endpointId === selectedEndpoint
    );
    endpointSelected?.serialNumber &&
      setSelectedSerialNumber(endpointSelected.serialNumber);
  }, [selectedEndpoint, userEndpoints]);
  const handleChangeDisplayTime = (event: SelectChangeEvent) => {
    setSelectedEndpoint(event.target.value);
    window.localStorage.setItem(LAST_ACCESSED_ENDPOINT, event.target.value);
  };

  return (
    <div>
      {fullFeedback && !isWrongURL && (
        <EfcFeedbackDialog
          open={isFeedbackOpen}
          onClose={() => setFeedbackOpen(false)}
          efcCode={efc?.EfcCodeStr ?? ''}
          efcId={efcId}
          efcDesciption={repairActionCardData?.efcProbableCause}
          moduleId={efc?.ModuleId}
          endpointId={selectedEndpoint}
          serialNumber={selectedSerialNumber}
        />
      )}
      {!fullFeedback && !isWrongURL && (
        <FeedbackScreen
          feedbackType="efc"
          efcCode={efc?.EfcCodeStr ?? ''}
          efcId={efcId}
          efcDesciption={repairActionCardData?.efcProbableCause}
          moduleId={efc?.ModuleId}
          isOpen={isFeedbackOpen}
          handleClose={() => setFeedbackOpen(false)}
          handleSubmit={() => setFeedbackOpen(false)}
        />
      )}
      {!isWrongURL && (
        <CustomRepairFeedbackScreen
          feedbackType="efc"
          efcCode={efc?.EfcCodeStr ?? ''}
          efcId={efcId}
          moduleId={efc?.ModuleId}
          endpointId={selectedEndpoint}
          serialNumber={selectedSerialNumber}
          isOpen={isCustomRepairFeedbackOpen}
          handleClose={() => setCustomRepairFeedbackOpen(false)}
          handleSubmit={() => setCustomRepairFeedbackOpen(false)}
        />
      )}
      {userEndpoints && userEndpoints.length > 0 && (
        <div className="h-18 flex bg-white">
          <div className="mt-2 flex-1">
            <Dropdown
              options={userEndpoints}
              value={selectedEndpoint}
              displayTime={displayTime}
              onChange={handleChangeDisplayTime}
              variant="standard"
            />
          </div>
        </div>
      )}
      {efc?.ImageId && (
        <div>
          <Image imageId={efc?.ImageId} imageType="efcs" />
        </div>
      )}
      {repairActionCardData && (
        <>
          <div className="ml-4 mt-3 text-xl font-medium leading-6 tracking-wide">
            {repairActionCardData?.efcProbableCause}
          </div>
          <div className="mt-1 ml-4 mb-4 text-xl leading-6 tracking-wide">
            {t('RepairActionCount {{count}}', {
              count: repairActionCardData?.repairActionRecords?.length ?? 0,
            })}
          </div>
        </>
      )}
      <SwipeableList type={Type.IOS}>
        {repairActionCardData?.repairActionRecords?.map(
          (repairActionRecord) => (
            <SwipeableListItem
              key={repairActionRecord.Id}
              leadingActions={
                <RepairActionCardLeadingActions
                  raId={repairActionRecord.Id}
                  efcId={efcId}
                  efcCode={efc?.EfcCodeStr ?? ''}
                  endpointId={selectedEndpoint}
                  serialNumber={selectedSerialNumber}
                />
              }
              trailingActions={
                <RepairActionCardTrailingActions
                  repairActionId={repairActionRecord.Id}
                  efcId={efcId}
                />
              }
            >
              <RepairActionCard
                repairActionItem={repairActionRecord}
                efcId={efcId}
                efcCode={efc?.EfcCodeStr ?? ''}
                selectedEndpoint={selectedEndpoint}
                endpointId={selectedEndpoint}
                serialNumber={selectedSerialNumber}
              />
            </SwipeableListItem>
          )
        )}
      </SwipeableList>
      <CustomRepairActionCard
        handleClick={() => setCustomRepairFeedbackOpen(true)}
      />

      <ScrollToTopButton />
      {isWrongURL && <PageNotFound />}
    </div>
  );
}
export default EFCPage;

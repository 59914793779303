import { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../acfs-apis/dwar-api-provider';
import useLogging from '../hooks/useLogging';
import LogParams from '../Models/LogParams';
import { LogType } from '../enums/LogType';
import { DATABASE_INIT_TIMESTAMP_KEY } from '../index-db';
import { DATABASE_LOADED_STATUS_KEY } from '../constants';
import { isToday } from '../utils';
import { OIDCConfig } from '../types';

import useLoadNotifications from '../hooks/useLoadNotifications';
import useLanguageTransformer from '../hooks/useLanguageTranformer';

function DBInitialize({ children }: PropsWithChildren<{}>) {
  const api = useApi();
  const logging = useLogging();
  const { i18n } = useTranslation();
  const [didFinishLoading, setDidFinishLoading] = useState(true);
  useLoadNotifications();
  window.localStorage.setItem(
    DATABASE_LOADED_STATUS_KEY,
    didFinishLoading.toString()
  );
  const langCode = useLanguageTransformer();
  useEffect(() => {
    (async function load() {
      const lastInitOfIndexedDB = window.localStorage.getItem(
        DATABASE_INIT_TIMESTAMP_KEY
      );

      const dbNeedsUpdating =
        lastInitOfIndexedDB === null || !isToday(Number(lastInitOfIndexedDB));

      if (dbNeedsUpdating) {
        window.localStorage.setItem(DATABASE_LOADED_STATUS_KEY, 'false');

        try {
          logging('info', 'Starting Data download.', '', false, null);
          const fetchContentResult = await api.fetchContent(langCode);
          window.localStorage.setItem(
            DATABASE_INIT_TIMESTAMP_KEY,
            window.Date.now().toString()
          );

          // Get oidConfig for logging build version
          let oidcConfig: OIDCConfig | undefined;
          try {
            oidcConfig = await api.fetchOIDCConfig();
          } catch (error) {
            logging(
              'warn',
              'Unable to load OIDC Config file',
              error,
              false,
              null
            );
          }
          const logParams: LogParams[] = [
            {
              key: LogType.Language,
              value: i18n.language,
            },
            {
              key: LogType.DSAFeedbackDlStatus,
              value: fetchContentResult.dsaFeedbackStatus,
            },
            {
              key: LogType.DSAFeedbackDlTimeMs,
              value: fetchContentResult.dsaFeedbackDownloadTime.toString(),
            },
            {
              key: LogType.PartsDlStatus,
              value: fetchContentResult.partsStatus,
            },
            {
              key: LogType.PartsDlTimeMs,
              value: fetchContentResult.partsDownloadTime.toString(),
            },
            {
              key: LogType.DsaDlStatus,
              value: fetchContentResult.dsaStatus,
            },
            {
              key: LogType.DsaDlTimeMs,
              value: fetchContentResult.dsaDownloadTime.toString(),
            },
            {
              key: LogType.BuildVer,
              value: oidcConfig?.BuildVersion ?? '',
            },
          ];
          logging('info', 'Initial data load', '', true, logParams);
          navigator.storage.estimate().then((value) => {
            logging(
              'info',
              `Indexed db quota: ${value.quota}`,
              '',
              false,
              null
            );
            logging(
              'info',
              `Indexed db usage: ${value.usage}`,
              '',
              false,
              null
            );
          });
        } catch (error) {
          logging('warn', 'Error loading data', error, false, null);
        }
        setDidFinishLoading(true);
        window.localStorage.setItem(
          DATABASE_LOADED_STATUS_KEY,
          didFinishLoading.toString()
        );
      }
    })();
  }, []);

  return <>{children}</>;
}

export default DBInitialize;

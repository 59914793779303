import { useCallback, useState } from 'react';
import { IOption } from '../components/EndpointDropdown';
import EndPoint, { IEndpointSummary } from '../Models/EndPoint';
import Task from '../Models/Task';
import { useApi } from '../acfs-apis/dwar-api-provider';
import db from '../index-db';
import { useTranslation } from 'react-i18next';

const useUserEndpointsDropdown = () => {
  const [userEndpoints, setUserEndpoints] = useState<IOption[]>([]);
  const api = useApi();
  const { t } = useTranslation();

  const fetchManualEndpoints = async () => {
    let endpointOptions: IOption[] = [] as IOption[];
    const manualEndpoints = await db.manualEndPoints.toArray();
    manualEndpoints.forEach((endpoint) => {
      if (endpoint && endpoint.serialNumber && endpoint.id) {
        const option = {
          serialNumber: endpoint.serialNumber,
          terminalId: endpoint.terminalId,
          endpointId: endpoint.id,
          customerName: endpoint.customerName,
        };
        endpointOptions.push(option);
      }
    });
    return endpointOptions;
  };

  const populateEndpointOptions = useCallback(
    async (tasks: Task[]) => {
      const endpointIds = tasks.map((task) => task.endpointId);
      let results: IEndpointSummary[] | EndPoint[] | undefined;
      try {
        results = await (
          await api.getEndpointSummariesByIds(endpointIds)
        ).json();
      } catch (e) {
        results = (await db.endPoints.bulkGet(endpointIds)).filter(
          (endpointIds) => endpointIds !== undefined
        ) as EndPoint[];
      }

      let endpointOptions =
        results?.map((endpoint) => {
          return {
            serialNumber: endpoint.serialNumber,
            terminalId: endpoint.terminalId,
            endpointId: endpoint.id,
            customerName: endpoint.customerName,
          };
        }) || [];

      let manualEndpointOptions: IOption[] = await fetchManualEndpoints();
      manualEndpointOptions.forEach((option) => {
        if (
          endpointOptions.find(
            (value) => option.endpointId === value.endpointId
          ) === undefined
        ) {
          endpointOptions.push(option);
        }
      });

      const option = {
        serialNumber: t('No Endpoint'),
        terminalId: '',
        endpointId: 'No Endpoint',
        customerName: '',
      };
      endpointOptions.push(option);
      setUserEndpoints(endpointOptions);
    },
    [api, t]
  );
  return [userEndpoints, populateEndpointOptions] as [
    IOption[],
    (tasks: Task[]) => Promise<void>
  ];
};

export default useUserEndpointsDropdown;

import React, { useEffect } from 'react';
import ProductionQuantityLimitsOutlinedIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/SmsOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { usePage } from '../../PageProvider';
import useLogging from '../../hooks/useLogging';
import LogParams from '../../Models/LogParams';
import MenuButton from './MenuButton';
import { IS_USER_AUTHENTICATED_KEY } from '../../constants';
import { USER_REALM_KEY } from '../../constants';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import { LogType } from '../../enums/LogType';
import { ESC_URL } from '../../constants';
import { publicPath } from '../../utils';
import useCheckUserIsPartner from '../../hooks/useCheckUserIsPartner';
import useUnreadNotificationsCount from '../../hooks/useUnreadNotificationsCount';
import useFieldFeedbackRequestedCount from '../../hooks/useFieldFeedbackRequestedCount';

function Menu() {
  const isMobile = useCheckMobileScreen();
  const { t } = useTranslation();
  const auth = useAuth();
  const logging = useLogging();
  const SearchQuery = isMobile ? '/search/query/everywhere' : '/search';

  const isPartner = useCheckUserIsPartner();
  const unReadNotificationsCount = useUnreadNotificationsCount();
  const fieldFeedbackRequestedCount = useFieldFeedbackRequestedCount();

  useEffect(() => {
    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.Menu,
      },
    ];
    logging('info', '', '', false, logParams);
  }, []);

  usePage(
    () => ({
      getTitle: () => t('ACFS'),
      belongsToNavBarItem: 'Menu',
      showACFSLogo: true,
    }),
    [t]
  );

  let esc_url = window.localStorage.getItem(ESC_URL);
  if (esc_url == null) {
    esc_url = '#';
  }

  const acfsIcon = () => (
    <div>
      <img src={publicPath('acfs.png')} className="h-8 w-8" />
    </div>
  );

  return (
    <div>
      <MenuButton to="/" icon={HomeOutlinedIcon} title={t('Home')} />
      <MenuButton
        to="/efccodes"
        icon={TerminalOutlinedIcon}
        title={t('EFC codes')}
      />
      <MenuButton
        to="/parts"
        icon={ConstructionOutlinedIcon}
        title={t('Parts')}
        disabled={isPartner}
      />
      <MenuButton
        to="/products"
        icon={ProductionQuantityLimitsOutlinedIcon}
        title={t('Products')}
        disabled={isPartner}
      />
      <MenuButton
        to="/repairs"
        icon={BuildCircleOutlinedIcon}
        title={t('Repairs')}
      />
      <hr className="my-2 border-concrete" />
      <MenuButton
        to={SearchQuery}
        icon={SearchOutlinedIcon}
        title={t('Search')}
      />
      <MenuButton
        to="/recent"
        icon={HistoryOutlinedIcon}
        title={t('Recently viewed')}
      />
      <MenuButton
        to="/saved"
        icon={BookmarkBorderOutlinedIcon}
        title={t('Saved items')}
      />
      <hr className="my-2 border-concrete" />
      <MenuButton to="/info" icon={InfoIcon} title={t('Info')} />

      <MenuButton
        to="/notifications"
        icon={NotificationsOutlinedIcon}
        title={t('Notifications')}
        badgeContent={unReadNotificationsCount}
        badgeColor="primary"
      />

      <MenuButton
        to="/settings"
        icon={SettingsOutlinedIcon}
        title={t('Settings')}
      />
      <MenuButton to="/help" icon={HelpOutlinedIcon} title={t('Help')} />
      <MenuButton
        to="/feedback"
        icon={FeedbackOutlinedIcon}
        title={t('Feedback')}
        badgeContent={fieldFeedbackRequestedCount}
        badgeColor="primary"
      />
      <MenuButton
        to=""
        icon={acfsIcon}
        title={t('Other Service Functions')}
        onClick={() => {
          window.location.href = esc_url ?? '#';
        }}
      />
      <MenuButton
        to=""
        icon={LogoutOutlinedIcon}
        title={t('Logout')}
        onClick={() => {
          window.localStorage.removeItem(IS_USER_AUTHENTICATED_KEY);
          window.localStorage.removeItem(USER_REALM_KEY);
          auth.signoutRedirect();
        }}
      />
    </div>
  );
}

export default Menu;

const translation = {
  'You switched to {{ language }}': 'คุณเปลี่ยนไปใช้ {{ language }}',
  'Thai': 'ไทย',
  'Home': 'หน้าหลัก',
  'Search': 'ค้นหา',
  'Saved': 'บันทึกแล้ว',
  'Me': 'ฉัน',
  'Menu': 'เมนู',
  'English': 'อังกฤษ',
  'AllConnect Field Support': 'AllConnect Field Support',
  'Settings': 'ตั้งค่า',
  'Language': 'ภาษา',
  'Saved Items': 'สิ่งที่บันทึกแล้ว',
  'My tasks': 'งานของฉัน',
  'Parts': 'อะไหล่',
  'Saved items': 'สิ่งที่บันทึกแล้ว',
  'Notifications': 'การแจ้งเตือน',
  'Help': 'ช่วย',
  'Feedback': 'ข้อเสนอแนะ',
  'Feedback Status': 'สถานะข้อเสนอแนะ',
  'Repair Feedback': 'ข้อเสนอแนะการซ่อมแซม',
  'EFC Feedback': 'EFC ข้อเสนอแนะ',
  'General Feedback': 'ข้อเสนอแนะทั่วไป',
  'Module Name': 'ชื่อโมดูล',
  'EFC Code': 'รหัส EFC',
  'Your Feedback': 'ข้อเสนอแนะของคุณ',
  'Field Feedback Requested': 'ร้องขอคำติชมภาคสนาม',
  'Level 4 to Review': 'ระดับ 4 เพื่อทบทวน',
  'In Process by Level 4': 'อยู่ระหว่างดำเนินการตามระดับ 4',
  'Processed': 'ดำเนินการแล้ว',
  'Logout': 'ออกจากระบบ',
  'Add to equipment list': 'เพิ่มไปยังรายการอุปกรณ์',
  'Download for offline': 'โหลดเก็บไว้ในเครื่อง',
  'Repair Action': 'การแก้ไขซ่อมแซม',
  'Repair action': 'การแก้ไขซ่อมแซม',
  'Part': 'อะไหล่',
  'Refreshed {{time}} min ago': 'อัพเดทเมื่อ {{time}} นาทีที่แล้ว',
  'Recently viewed': 'ดูล่าสุด',
  'View all': 'ดูทั้งหมด',
  'Clear All': 'ลบทั้งหมด',
  'Expand all': 'ขยายทั้งหมด',
  'Collapse all': 'ย่อทั้งหมด',
  'Search results': 'ผลการค้นหา',
  'Repairs': 'ซ่อม',
  'Downloading content...': 'ดาวน์โหลดเนื้อหา...',
  'Bookmarks': 'Bookmarks',
  'Downloads': 'ดาวน์โหลด',
  'Recent': 'ล่าสุด',
  'Recent codes': 'รหัสล่าสุด',
  'Saved codes': 'รหัสที่ถูกบันทึก',
  'Updates': 'อัพเดท',
  'Clear': 'เคลียร์',
  'Updated Content for {{item}}.': 'อัพเดทเนื้อหาสำหรับ {{item}}',
  '{{item}} has been saved': '{{item}} ได้ถูกบันทึกแล้ว',
  '{{item}} could not be saved': '{{item}} ไม่สามารถบันทึกได้',
  '{{item}} has been removed from saved':
    '{{item}} ได้ถูกลบออกจากที่เคยบันทึกไว้แล้ว',
  '{{item}} could not be removed from saved':
    '{{item}} ไม่สามารถลบออกจากที่เคยบันทึกไว้ได้',
  'Difficulty': 'ยุ่งยาก',
  'Repair time': 'เวลาที่ใช้ในการซ่อม',
  'min_one': '1 นาที',
  'min_other': '{{count}} นาที',
  'min_zero': '0 นาที',
  'Rating': 'เรทติ้ง',
  'Thank you for the feedback': 'ขอบคุณสำหรับการให้ข้อมูล',
  'Not relevant': 'ไม่เกี่ยวข้อง',
  'Relevant': 'เกี่ยวข้อง',
  'Fixed my issue': 'ปัญหาได้รับการแก้ไข',
  'Leave feedback': 'ให้ข้อเสนอแนะ',
  'Save item': 'บันทึก',
  'Low': 'Low',
  'Medium': 'Medium',
  'High': 'High',
  'This was relevant info': 'นี่คือข้อมูลที่เกี่ยวข้อง',
  'This was not relevant info': 'นี่คือข้อมูลที่ไม่เกี่ยวข้อง',
  'Add to saved items': 'เพิ่มไปยังรายการที่บันทึกแล้ว',
  'Remove from downloads': 'นำออกจากที่ดาวน์โหลด',
  'Search AllConnect Field Support': 'ค้นหา AllConnect Field Support',
  'Filters': 'กรอง',
  'Page not found': 'ไม่พบหน้านี้',
  'Return home': 'กลับสู่หน้าหลัก',
  'Clear all bookmarks': 'ล้างบุ๊กมาร์กทั้งหมด',
  'Search all EFC codes': 'ค้นหารหัส EFC ทั้งหมด',
  'Search all parts': 'ค้นหาอะไหล่ทั้งหมด',
  'Results ({{resultsCount}})': 'ผลลัพท์ ({{resultsCount}})',
  '{{item}} is already saved': '{{item}} ถูกบันทึกแล้ว',
  'Details': 'รายละเอียด',
  'Equipment': 'อุปกรณ์',
  'Notes': 'บันทึก',
  'Preventive Insights': 'ข้อมูลเชิงลึกเชิงป้องกัน',
  'Service Insights': 'ข้อมูลเชิงลึกด้านบริการ',
  'Equipment Information': 'ข้อมูลอุปกรณ์',
  'Equipment ID': 'หมายเลขอุปกรณ์',
  'Model': 'รุ่น',
  'Terminal ID': 'หมายเลขเครื่อง',
  'Device serial number': 'serial number ของอุปกรณ์',
  'EFC codes': 'รหัส EFC',
  'Steps': 'ขั้นตอน',
  'Module content': 'โมดูล เนื้อหา',
  'Repair action content': 'เนื้อหาคำแนะนำการซ่อม',
  'Fixed issue': 'ปัญหาที่ได้รับการแก้ไข',
  'Part Number {{partId}} copied to the clipboard':
    'หมายเลขพาร์ท {{partId}} คัดลอกไปยังคลิปบอร์ด',
  'Relationship tree': 'แผนผังความสัมพันธ์',
  'Potential substitutions': 'พาร์ททดแทน',
  'Loading': 'กำลังโหลด',
  'Removed from saved searches': 'นำออกจากรายการค้นหาที่บันทึกไว้',
  'Added to saved searches': 'เพิ่มไปยังรายการค้นหาที่บันทึกไว้',
  'All bookmarks cleared': 'ล้างบุ๊กมาร์กทั้งหมดแล้ว',
  'No bookmarks to clear': 'ไม่มีบุ๊กมาร์กให้ล้าง',
  'All recent items cleared': 'รายการล่าสุดทั้งหมดถูกเคลียร์แล้ว',
  'No recent items to clear': 'ไม่มีรายการล่าสุดที่ต้องเคลียร์',
  'You have no recently view history.': 'คุณไม่มีประวัติรายการล่าสุดที่เคยดู',
  'Switching language is done in 2 phases. The static data such as labels and headings will be switched immediately. The content data requires an online connection and can take up to a minute or more depending on your connection to refresh the new language choice.  Until content is downloaded the app will continue to show the previous language for content.':
    'การเปลี่ยนภาษาจะดำเนินการเป็น 2 ช่วง โดยเริ่มเปลี่ยนภาษาของชื่อหัวข้อต่างๆก่อน แล้วจึงเปลี่ยนภาษาในส่วนเนื้อหาภายหลัง ซึ่งจำเป็นต้องมีการเชื่อมต่อกับอินเตอร์เน็ตโดยระยะเวลาในการเปลี่ยนภาษาในส่วนเนื้อหาอาจเร็วหรือช้ากว่าหนึ่งนาทีขึ้นกับคุณภาพของอินเตอร์เน็ต ในระหว่างที่การแปลภาษาในส่วนของเนื้อหายังดำเนินการไม่แล้วเสร็จ หน้าจอจะแสดงผลเป็นภาษาเดิมจนกระทั่งดำเนินการเสร็จ',
  'Signing you in': 'คุณกำลังเข้าสู่ระบบ',
  'Signing you out': 'คุณกำลังออกจากระบบ',
  'Oops': 'อ๊ะ',
  'Switching language to {{ language }}': 'กำลังเปลี่ยนเป็นภาษา {{ language }}',
  'Data has been fetched in': 'ข้อมูลได้ถูกเรียกใน',
  'Failed to fetch data in': 'ไม่สามารถเรียกข้อมูลใน',
  'No results': 'ไม่มีผลลัพท์',
  'URL': 'URL',
  'User Name': 'User Name',
  'ACFS App': 'ACFS App',
  'ACFS UI': 'ACFS ',
  'Offline Data': 'ข้อมูลออฟไลน์',
  'Info': 'ข้อมูล',
  'Repair': 'ซ่อม',
  'RepairActionCount 1': '1 การดำเนินการแก้ไข',
  'RepairActionCount {{count}}': '{{count}} การดำเนินการแก้ไข',
  'RepairActionCount 0': '0 การดำเนินการแก้ไข',
  'All': 'ทั้งหมด',
  'Sync': 'ซิงค์(sync)',
  'Last 30 days': '30 วันล่าสุด',
  'Not yet implemented': 'ยังไม่ได้ดำเนินการ',
  'Categories': 'หมวดหมู่',
  'Repair actions': 'การดำเนินการแก้ไข',
  'Module': 'โมดูล',
  'Preferences': 'การตั้งค่า',
  'Service Manuals': 'คู่มือการบริการ',
  'Service Bulletins': 'กระดานข่าวบริการ',
  'FCOs': 'FCOs',
  'FAQs': 'คำถามที่พบบ่อย',
  'Training Content': 'เนื้อหาการอบรม',
  'Your feedback has been sent': 'ข้อเสนอแนะของคุณได้ถูกส่งแล้ว',
  'Failed to send your feedback': 'ไม่สามารถส่งข้อเสนอแนะได้สำเร็จ',
  'Comments are required': 'ให้กรอกความเห็นด้วย',
  'Comments should not be more than 100 characters':
    'ความเห็นไม่ควรยาวเกิน 100 ตัวอักษร',
  'Max {{numOfPhotos}} photos can be uploaded':
    'สามารถอัพโหลดได้สูงสุด {{numOfPhotos}} ภาพ',
  'Add this Task - {{taskNumber}}': 'เพิ่มงานหมายเลข - {{taskNumber}}',
  "Other '{{inputValue}}'": "อื่นๆ '{{inputValue}}'",
  'Other (Type to add) / Blank': 'อื่น ๆ (ป้อนข้อความเพิ่มเติม) / ว่าง',
  'General feedback': 'ข้อคิดเห็นทั่วไป',
  'Feedback: EFC code - {{efcCode}}': 'ข้อคิดเห็น: รหัส EFC - {{efcCode}}',
  'Module: {{moduleName}}': 'โมดูล: {{moduleName}}',
  'Feedback for a specific EFC Code, Repair Action, or Part should be submitted using the feedback option available on those specific entries.':
    'ข้อคิดเห็นแบบเฉพาะเจาะจงของ รหัส EFC, คำแนะนำการซ่อม หรือ อะไหล่ สามารถส่งได้โดยใช้ตัวเลือกที่มีของแต่ละหัวข้อนั้นๆ',
  'Corrective action required': 'จำเป็นต้องได้รับการแก้ไข',
  'Yes': 'ใช่',
  'No': 'ไม่ใช่',
  'Task number': 'หมายเลขงาน',
  'Select or Type to add': 'เลือกตัวเลือก หรือ พิมพ์เพิ่มเติม',
  'Comments': 'ข้อคิดเห็น',
  'Delete': 'ลบ',
  'Open Camera': 'เปิดกล้อง',
  'Upto {{maxFiles}} images can be selected':
    'สามารถเลือกภาพได้ไม่เกิน {{maxFiles}} ไฟล์',
  'Cancel': 'ยกเลิก',
  'Add': 'เพิ่ม',
  'Add equipment': 'เพิ่มอุปกรณ์',
  'Serial number': 'Serial number',
  'Scan the equipment code or enter the serial number to view the insights and details for that piece of equipment.':
    'ทำการสแกน หรือ กรอกหมายเลข serial number เพื่อดูข้อมูลรายละเอียดของอุปกรณ์',
  'Last synced: {{time}} min ago': 'ซิงค์ครั้งล่าสุด: {{time}} นาทีที่แล้ว',
  'Send': 'ส่ง',
  "Task Number must be provided if 'Corrective action required' is Yes":
    'ต้องระบุหมายเลขงานในกรณีที่งานนั้นเป็นงานที่จำเป็นต้องได้รับการแก้ไข',
  'Time to fix (minutes)': 'ระยะเวลาที่ใช้ในการซ่อม (นาที)',
  'Time to fix should not be more than 999':
    'ระยะเวลาที่ใช้ในการซ่อมไม่ควรเกิน 999',
  'Please enter positive numbers only': 'กรอกหมายเลขที่มีค่าเป็นบวกเท่านั้น',
  'Please enter numbers only': 'กรอกข้อมูลที่เป็นหมายเลขเท่านั้น',
  'Clear filters': 'เคลียร์ตัวกรอง',
  'Show results': 'แสดงผลลัพท์',
  'Your feedback has been submitted': 'ความเห็นของคุณได้ถูกส่งแล้ว',
  'This EFC is related to an enrichment link that is currently active.Please let us know if the enrichment is relevant (appropriate action to perform).If you want to leave additional feedback select "Leave feedback"':
    'รหัส EFC มีความเกี่ยวข้องกับลิงค์คำแนะนำการซ่อมปัจจุบัน โปรดบอกให้เราทราบว่าคำแนะนำการซ่อมที่ได้รับนั้นมีความเหมาะสมหรือไม่ หากต้องการให้ข้อมูลเพิ่มเติมให้เลือก "Leave feedback"',
  'Products': 'ผลิตภัณฑ์',
  'Product Number {{productId}} copied to the clipboard':
    'หมายเลขผลิตภัณฑ์ {{productId}} ถูกเก็บไว้ในหน่วยความจำชั่วคราว',
  'View Groups': 'ดูรายชื่อกลุ่ม',
  'Search all products': 'ค้นหาทุกผลิตภัณฑ์',
  'Current': 'ปัจจุบัน',
  'History': 'ประวัติ',
  'No equipment data found for serial number {{number}}.':
    'ไม่พบข้อมูลอุปกรณ์ serial number {{number}}.',
  'Unable to retrieve equipment data for the serial number {{number}}.':
    'ไม่สามารถดึงข้อมูลอุปกรณ์ของ serial number {{number}}.',
  'Unable to retrieve equipment data. Falling back to offline data.':
    'ไม่สามารถดึงข้อมูลอุปกรณ์ได้ จึงถอยกลับไปใช้ข้อมูลแบบออฟไลน์',
  'Unable to retrieve service task. Falling back to offline data.':
    'เรียกข้อมูลงานบริการไม่สำเร็จ, กลับไปยังข้อมูลออฟไลน์',
  'Please wait while data is being loaded in {{language}}':
    'โปรดรอในขณะที่กำลังโหลดข้อมูลเป็นภาษา {{language}}',
  'Errors': 'Error',
  'Tasks': 'งาน',
  'History Parts': 'ประวัติอะไหล่',
  'Unable to retrieve Service Task history.':
    'ไม่สามารถดึงข้อมูลประวัติงานบริการได้',
  'Recommended Parts': 'อะไหล่ที่แนะนำ',
  'Service Task Details': 'รายละเอียดงานบริการ',
  'Task Number': 'หมายเลขงาน',
  'Task Status': 'สถานะของงาน',
  'Problem': 'ปัญหา',
  'Task Type': 'ประเภทของงาน',
  'Customer': 'ลูกค้า',
  'Effective Date': 'วันที่มีผล',
  'Device Description': 'คำอธิบายอุปกรณ์',
  'Device Serial Number': 'Serial Number ของอุปกรณ์',
  'EndPoint Id': 'EndPoint Id',
  'Service Task Notes {{length}}.': 'บันทึกงานบริการ {{length}}.',
  'Part {{partId}} cannot be found in DNSpares data':
    'ไม่พบอะไหล่ {{partId}} ในข้อมูล DNSpares',
  'Number': 'หมายเลข',
  'Site Number': 'หมายเลขสถานที่',
  'Address': 'ที่อยู่',
  'Contact Date': 'วันที่ติดต่อ',
  'Contact Name': 'ผู้ที่ติดต่อ',
  'Contact Email': 'อีเมลล์',
  'Contact Phone': 'หมายเลขโทรศัพท์',
  'Contract Hours': 'ชั่วโมงสัญญา',
  'Site Messages:': 'ข้อความในเว็บไซต์:',
  'Type': 'ประเภท',
  'Product': 'ผลิตภัณฑ์',
  'Desc': 'คำอธิบาย',
  'Task Number should not be more than 30 characters':
    'หมายเลขงานไม่ควรเกิน 30 ตัวอักษร',
  'Select Photos': 'เลือกรูปภาพ',
  'Session Expired': 'เซสชันหมดอายุแล้ว',
  'The current session has expired while offline. Please bring the device back online and select the Login button below to perform the login and take you back to the home screen.':
    'การดำเนินการได้หมดเวลาแล้ว โปรดทำให้อุปกรณ์กลับมาออนไลน์ แล้วเลือก Login เพื่อเข้าสู่หน้าหลัก',
  'Login': 'Login',
  'Please provide your feedback in order to improve the content in the ACFS Site not related to a specific EFC or Repair Action.':
    'โปรดให้ข้อเสนอแนะเพื่อการปรับปรุงเนื้อหาใดๆของ ACFS',
  'Please provide your feedback in order to improve the content that follows this EFC Code.':
    'โปรดให้ข้อเสนอแนะเพื่อการปรับปรุงเนื้อหาของรหัส EFC นี้',
  'Is this Repair Action missing anything? For example, should the star rating or time to fix be adjusted? Please provide your feedback in order to improve the content of this specific Repair Action and its association with the EFC that prompted this.':
    'คำแนะนำการซ่อมนี้มีอะไรบกพร่องหรือไม่ ตัวอย่างเช่น จำนวนดาว หรือ ระยะเวลาในการซ๋อม ควรได้รับการปรับเปลี่ยนหรือไม่ โปรดให้ข้อเสนอแนะเพื่อการปรับปรุงคำแนะนำการซ่อมของอาการนี้',
  'Select "Yes" if you are recommending that a change needs to be made by the administrators.':
    'ให้เลือก "ใช่" หากคุณเห็นว่าผู้ดูแลระบบควรทำการแก้ไข',
  'Feedback is not mandatory.Please use English if possible. Feedback is used to improve the content in ACFS. Feedback can be shared with management to ensure quality. Administrators may contact individuals for additional information to help clarify any information needed to improve content or other business purposes.':
    'ข้อเสนอแนะตามความสมัครใจ หากเป็นไปได้โปรดใช้ภาษาอังกฤษในการให้ข้อเสนอแนะ ซึ่งข้อมูลนี้จะใข้เพือการปรับปรุงเนื้อหาของ ACFS หรือส่งให้ผู้บริหารเพื่อหาแนวทางทำให้ระบบมีคุณภาพ ซึ่งผู้ดูแลระบบอาจะมีการติดต่อไปที่คุณเพื่อขอรายละเอียดเพิ่มเติมเกี่ยวกับข้อเสนอแนะนี้',
  'OfflineMessage':
    'เมื่อ ACFS ทำงานแบบออฟไลน์ อาจจะขาดเนื้อหาบางส่วน ซึ่งขึ้นกับความถี่ในการอัพเดทเนื้อหาและข้อจำกัดของพื้นที่เก็บข้อมูลในอุปกรณ์ของคุณ',
  'OfflineMessage1':
    'บน เพจหลัก และ เพจฉัน นั้น ระบบจะแสดง งานของฉัน เฉพาะงานที่มีอยู่ในอุปกรณ์เท่านั้น',
  'OfflineMessage2':
    'บนเพจที่เกี่ยวข้องกับอุปกรณ์ จะแสดงข้อมูลอุปกรณ์และข้อมูลงานบริการ เฉพราะที่มีข้อมูลอยู่ในอุปกณ์เท่านั้น',
  'OfflineMessage3':
    'เพจ บันทึก จะแสดงเฉพาะบันทึกของงานบริการที่มีข้อมูลอยู่ในอุปกรณ์เท่านั้น',
  'OfflineMessage4':
    'ฟังก์ชั่น เพิ่มอุปกรณ์ ใน เพจฉัน และ เพจอุปกรณ์ จะสามารถใช้งานได้ เนื่องจากไม่สามารถดึงอุปกรณ์คู่มือด้วย serial number ที่ผู้ใช้ระบุมาได้',
  'Feedback: Repair # {{repairActionId}}':
    'ข้อคิดเห็น: การซ่อม # {{repairActionId}}',
  'Training': 'การอบรม',
  'General Help': 'ความช่วยเหลือทั่วไป',
  'Offline Disclaimer': 'ข้อจำกัดความรับผิดชอบในกรณีออฟไลน์',
  'Nothing here yet': 'ยังไม่มีอะไรในนี้',
  'Unable to retrieve tasks. Falling back to offline data.':
    'ดึงข้อมูลงานไม่สำเร็จ จึงกลับไปใช้ข้อมูลแบบออฟไลน์ในอุปกรณ์',
  'Category': 'หมวดหมู่',
  'Family': 'ครอบครัว',
  'Skill level required': 'ระดับความชำนาญที่ต้องการ',
  'Sort by': 'เรียงลำดับโดย',
  'Subsystem': 'ระบบย่อย',
  'Recent records': 'ข้อมูลล่าสุด',
  'Systems': 'ระบบ',
  'Task - {{taskNumber}}': 'งาน - {{taskNumber}}',
  'Add New': 'เพิ่มข้อมูลใหม่',
  'Everywhere': 'ทุกที่',
  'Keywords': 'คำหลัก',
  'The details page contains content only when there is an active call assigned to the current user for this equipment.':
    'หน้ารายละเอียดจะมีข้อมูลเฉพาะในกรณีที่มีงานมอบหมายให้กับผู้ใช้ปัจจุบันสำหรับอุปกรณ์นี้',
  'OfflineMessage5':
    'รูปภาพต่างๆในเนื้อหาของแอปพลิเคชั่น อย่างเช่น ภาพ EFC, ภาพขั้นตอนการซ่อม, ภาพอะไหล่ จะไม่ถูกแสดงผลหากผู้ใช้อยู่ในโหมดออฟไลน์',
  'Module(EFC only)': 'โมดูล (EFC เท่านั้น)',
  'German': 'เยอรมัน',
  'Francias': 'ฝรั่งเศษ',
  'Polish': 'โปรแลนด์',
  'Espanol': 'สเปน',
  'Indonesian': 'อินโดนีเซีย',
  'Chinese': 'จีน',
  'Vietnamese': 'เวียดนาม',
  'Portuguese': 'โปรตุเกส',
  'Turkish': 'ตุรกี',
  'If no stock, replaces': 'หากไม่มีสต็อกให้แทนที่',
  'If no stock, replaced by': 'หากไม่มีสต็อกให้แทนที่ด้วย',
  'Replaces': 'แทน',
  'Replaced by': 'แทนที่ด้วย',
  'Repair Id': 'รหัสซ่อม',
  'View Child Ids': 'ดูรหัสพาร์ทย่อย',
  'Device Id': 'รหัสอุปกรณ์',
  'Equipment with serial number {{number}} added Succesfully.':
    'อุปกรณ์ที่มีหมายเลขซีเรียล {{number}} เพิ่มเข้ามาได้สําเร็จ',
  'mfg': 'หมายเลขการผลิต',
  'Reporting System': 'ระบบการรายงาน',
  'Update': 'อัปเดต',
  'No Endpoint': 'No Endpoint',
  'Repair Action performed': 'ปฏิบัติตามคำแนะนำการซ่อมแล้ว',
  'Update: User names are now visible in task history. You’ll see names of users, and others will see yours. This change helps identify previous users, making it easier to contact them for further information if needed.':
    'อัปเดต: ชื่อผู้ใช้จะปรากฏในประวัติของงาน โดยคุณจะเห็นชื่อผู้ใช้คนอื่นและผู้ใช้งานคนอื่นจะเห็นชื่อของคุณ การเปลี่ยนแปลงนี้จะช่วยในการระบุผู้ใช้งานก่อนหน้านี้ ทำให้สะดวกต่อการติดต่อพวกเขาเพื่อขอข้อมูลเพิ่มเติม หากจำเป็น',
  'The ACDE Knowledgebase content is currently being downloaded to this device.  We apologize for the inconvenience.  The page will automatically redirect when the content load is complete.':
    '(ขออภัยในความไม่สะดวกเนื่องจากขณะนี้อยู่ระหว่างการดาวน์โหลดเนื้อหา ACDE เพิ่มเดิมลงมายังอุปกรณ์ของท่าน ซึ่งระบบจะแสดงเนื้อหาโดยอัตโนมัติเมื่อการดาวน์โหลดข้อมูลเสร็จสิ้นแล้ว)',
  'We apologize for the inconvenience.  The link used is not a valid location in ACFS.':
    '(ขออภัยในความไม่สะดวก ลิงค์นี้ไม่ถูกต้องในระบบ ACFS)',
  'The selected endpoint on the Repair Action Page does not match one of the endpoint of the active service task assigned. Select OK to continue. Cancel to change.':
    'เครื่องที่ถูกเลือกบนหน้าคำแนะนำการซ่อมไม่ตรงกับเครื่องหนึ่งของงานที่ได้ถูกมอบหมาย เลือก OK เพื่อดำเนินการต่อ หรือเลือก Cancel เพื่อเปลี่ยนแปลง',
  'Select “Yes” if you are recommending that a new Repair Action needs to be created by the administrators.':
    'เลือก Yes ถ้าคุณต้องการแนะนำให้ผู้ดูแลระบบทำการเพิ่มคำแนะนำการซ่อมใหม่เพิ่มเติม',
  'Select this repair action template to specify the action completed':
    'เลือก Yes ถ้าคุณต้องการแนะนำให้ผู้ดูแลระบบทำการเพิ่มคำแนะนำการซ่อมใหม่เพิ่มเติม',
  'Custom Repair Performed': 'ได้ดำเนินการซ่อมในด้วยวิธีการของตนเอง',
  'Submit': 'ส่ง',
  'Needs reviewed for new RA': 'คำแนะนำการซ๋อมใหม่จำเป็นต้องได้รับการทบทวน',
  'Title': 'หัวข้อ',
  'Title of repair': 'หัวข้อการซ่อม',
  'Description of repair': 'คำอธิบายการซ่อม',
  'Duration to complete repair (minutes)': 'ระยะเวลาที่ต้องใช้สำหรับการซ่อม',
  '3 or more Characters are required for the search.':
    'ต้องการอีกอย่างน้อย 3 ตัวอักษรเพื่อจะทำการค้นหา',
  'Title is required': 'จำเป็นต้องระบุชื่อหัวเรื่องด้วย',
  'Title should not be more than 200 characters':
    'ชื่อหัวเรื่องต้องมีความยาวไม่เกิน 200 ตัวอักษร',
  'Details is required': 'จำเป็นต้องมีรายละเอียดด้วย',
  'Switching language is done in 2 phases. The static data such as labels and headings will be switched immediately. The content data requires an online connection and can take up to a minute or more depending on your connection to refresh the new language choice. Until content is downloaded the app will continue to show the previous language for content.  Recently viewed and Saved items will be the last to update and can take up to an additional minute.':
    'การสลับภาษาจะมีอยู่สองขั้นตอน โดยเริ่มจากการสลับภาษาข้อมูลคงที่ก่อน เช่น หัวข้อต่างๆ จะถูกสลับภาษาโดยทันที ส่วนข้อมูลส่วนเนื้อหานั้น ระบบจำเป็นต้องมีการเชื่อมแบบออนไลน์ก่อน จึงจะทำการโหลดเนื้อหาจนกระทั้งแล้วเสร็จสมบูรณ์ก่อนที่จะทำการสลับภาษาได้ ซึ่งในขั้นตอนนี้อาจใช้เวลาถึงหนึ่งนาที หรือนานกว่านั้น ซึ่งขึ้นอยู่กับคุณภาพของอินเน็ตที่เชื่อมต่ออยู่ โดยในระหว่างที่โหลดข้อมูลยังไม่แล้วเสร็จ ระบบจะแสดงข้อมูลเป็นภาษาเดิมล่าสุดก่อนที่จะมีการเลือกเปลี่ยนภาษา',
  'No results Or Multiple Results, please narrow your search':
    'โปรดกำหนดเงื่อนไขการค้นหาข้อมูลให้แคบลง เนื่องจากไม่พบผลลัพธ์การค้นหา หรือพบผลลัพธ์มากเกินไป',
  'Enter the serial number to view the insights and details for that piece of equipment.':
    'ป้อนหมายเลข serial number เพื่อดูรายละเอียดข้อมูลเชิงลึก',
  'Open': 'เปิด',
  'Interrupt': 'Interrupt',
  'Incomplete': 'ไม่สมบูรณ์',
  'Closed': 'ปิดแล้ว',
  'Cancelled': 'ยกเลิกแล้ว',
  'Scanned successfully, Redirecting to the scanned link':
    'แสกนเสร็จแล้ว กำลังเปิดลิงค์',
  'Search Serial number': 'ค้นหาหมายเลขซีเรียล',
  'No System is Selected': 'ไม่มีการเลือกอุปกรณ์',
  'Cancelled Tasks': 'งานที่ยกเลิก',
  'Allowed': 'อนุญาต',
  'Not Allowed': 'ไม่ได้รับอนุญาต',
  'Open Task UserId': 'เปิดงาน UserId',
  'Observations': 'ข้อสังเกต',
  'GroupName or UserID': 'ชื่อกลุ่มหรือรหัสผู้ใช้',
  'AI Summary': 'สรุป AI',
  'Chronic / Worst Performer Details': 'รายละเอียดนักแสดงเรื้อรัง/แย่ที่สุด',
  'Triage Notes': 'หมายเหตุการคัดแยก',
  'Search By': 'ค้นหาโดย',
  'Serial Number': 'หมายเลขซีเรียล',
  'Tag': 'แท็ก',
  'Temporarily add equipment': 'เพิ่มอุปกรณ์ชั่วคราว',
  'System': 'ระบบ',
  'Machine Name': 'ชื่อเครื่อง',
  'No data is available': 'การเชื่อมต่อ',
  'Connections': 'การเชื่อมต่อ',
  'Non-Connected': 'ไม่เชื่อมต่อ',
  'Part Recommendations': 'คำแนะนำส่วนหนึ่ง',
  'Please wait for the daily update.  The page will automatically refresh when completed.':
    'กรุณารอการอัพเดททุกวัน หน้าจะรีเฟรชโดยอัตโนมัติเมื่อเสร็จสิ้น',
  'Install Date': 'วันที่ติดตั้ง',
  'ACDE KB daily update is currently in progress. Part navigation is not available. RepairAction indicator is also not available. Please try again when the content has been downloaded.':
    'ขณะนี้อยู่ระหว่างการอัปเดต ACDE KB รายวัน การนำทางบางส่วนไม่พร้อมใช้งาน ตัวบ่งชี้ RepairAction ก็ไม่สามารถใช้ได้เช่นกัน โปรดลองอีกครั้งเมื่อดาวน์โหลดเนื้อหาแล้ว',
  'No Equipment data available for the Endpoint {{endPointId}}.':
    'ไม่มีข้อมูลอุปกรณ์สำหรับจุดสิ้นสุด {{endPointId}}',
  'Duplicate endpoint has been identified. Historical EFCs may have come from multiple endpoints. Caution should be taken when reviewing the history of this endpoints. Validate that Harvester is setup properly with the correct endpoint registration.':
    'พบจุดสิ้นสุดที่ซ้ำกัน EFC ในอดีตอาจมาจากจุดสิ้นสุดหลายจุด ควรใช้ความระมัดระวังเมื่อตรวจสอบประวัติของจุดสิ้นสุดเหล่านี้ ตรวจสอบว่า Harvester ได้รับการตั้งค่าอย่างถูกต้องด้วยการลงทะเบียนจุดสิ้นสุดที่ถูกต้อง',
  'Duplicate Endpoint': 'จุดสิ้นสุดซ้ำซ้อน',
  'Inventory': 'รายการสิ่งของ',
  'Entitlements': 'สิทธิ',
  'Green': 'สีเขียว',
  'Yellow': 'สีเหลือง',
  'Red': 'สีแดง',
  'Empty': 'ว่างเปล่า',
  'Entitlement is connected and sending data as expected.':
    'เชื่อมต่อการให้สิทธิ์และส่งข้อมูลตามที่คาดไว้',
  'Entitlement is connected, but not sending data as expected.  Review the detailed message on the entitlement details page. Click on card to get details on how to resolve.':
    'เชื่อมต่อการให้สิทธิ์แล้ว แต่ไม่ได้ส่งข้อมูลตามที่คาดไว้  ตรวจสอบข้อความโดยละเอียดในหน้ารายละเอียดการให้สิทธิ์ คลิกที่การ์ดเพื่อดูรายละเอียดวิธีแก้ปัญหา',
  'Entitlement is expected, but is not connected and no data.  Click on card to get details on how to resolve.':
    'คาดว่าจะได้รับสิทธิ์ แต่ไม่ได้เชื่อมต่อและไม่มีข้อมูล  คลิกที่การ์ดเพื่อดูรายละเอียดวิธีแก้ปัญหา',
  'Entitlement does not send information as to the current status. No action is required.':
    'สิทธิไม่ได้ส่งข้อมูลถึงสถานะปัจจุบัน ไม่จำเป็นต้องดำเนินการใดๆ',
  'A disconnected system was previously connected and sending data to ACDE. However, data transmission has now stopped, exceeding the expected timeframe for this connection. Please refer to the ‘Last Data’ field to determine when data was last received.':
    'ระบบที่ถูกตัดการเชื่อมต่อได้เชื่อมต่ออยู่ก่อนหน้านี้และกำลังส่งข้อมูลไปยัง ACDE อย่างไรก็ตาม การส่งข้อมูลได้หยุดลงแล้ว ซึ่งเกินกรอบเวลาที่คาดไว้สำหรับการเชื่อมต่อนี้ โปรดดูที่ช่อง "ข้อมูลล่าสุด" เพื่อระบุว่าได้รับข้อมูลล่าสุดเมื่อใด',
  'Disconnected': 'ถูกตัดการเชื่อมต่อ',
  'Software Updated': 'อัปเดตซอฟต์แวร์',
  'Software': 'ซอฟต์แวร์',
  'components updated': 'ส่วนประกอบได้รับการอัพเดท',
  'component updated': 'ส่วนประกอบได้รับการอัพเดต',
};
export default translation;

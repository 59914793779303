import React, { forwardRef, Ref, useImperativeHandle, useRef } from 'react';
import { useState, useEffect } from 'react';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';

import Icon from '../../components/Icon';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import useNavigateToPart from '../../hooks/useNavigateToPart';
import useNavigateToEfc from '../../hooks/useNavigateToEfc';
import useNavigateToRepairAction from '../../hooks/useNavigateToRepairAction';
import { useApi } from '../../acfs-apis/dwar-api-provider';
import {
  ModifiedTimeline,
  modifiedTimeStamps,
} from '../../Models/TimeLineEvent';
import ServiceTaskModal from './ServiceTaskModal';
import CustomRepairModal from './CustomRepairModal';
import useLogging from '../../hooks/useLogging';
import db from '../../index-db';
import Task from '../../Models/Task';
import { TimeLineEventType } from '../../Models/Enumerations';
import TimelineTaskIcon from './TimelineTaskIcon';
import useGetRAPerformedIcon from '../../hooks/useGetRAPerformedIcon';
import TimelineCollapsibleManager from './TimelineCollapsibleManager';
import { useTranslation } from 'react-i18next';

interface Props {
  timeLineEvent: ModifiedTimeline;
  dataSource: string;
  currentMenu: string;
  onTimelineClick: () => void;
  isClickable?: boolean;
}
export type CardRef = {
  scrollIntoView: (option: boolean | ScrollIntoViewOptions | undefined) => void;
} | null;
export const calculateTime = (currentTimeValue: string) => {
  const currentDate = new Date(Date.now());
  const timestamp = new Date(currentTimeValue);

  const diffInTime = currentDate.getTime() - timestamp.getTime();
  const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

  const hours =
    timestamp.getHours() < 10
      ? `0${timestamp.getHours()}`
      : timestamp.getHours().toString();
  const minutes =
    timestamp.getMinutes() < 10
      ? `0${timestamp.getMinutes()}`
      : timestamp.getMinutes().toString();

  const weekMonthDay = timestamp
    .toLocaleDateString(undefined, {
      month: 'short',
      day: 'numeric',
      weekday: 'short',
    })
    .split(',')
    .join(' ');
  return { diffInDays, hours, minutes, weekMonthDay };
};
function TimelineEvent(
  {
    timeLineEvent,
    dataSource,
    currentMenu,
    onTimelineClick,
    isClickable,
  }: Props,
  ref: Ref<CardRef>
) {
  const navigateToPart = useNavigateToPart();
  const navigateToEfc = useNavigateToEfc();

  const navigateToRepairAction = useNavigateToRepairAction();
  const api = useApi();
  const [serviceTaskData, setServiceTaskData] = useState({} as Task);
  const [event, setEvent] = useState('');
  const logging = useLogging();
  const [isServiceTaskModalOpen, setServiceTaskModalOpen] = useState(false);
  const [isCustomRepairModalOpen, setCustomRepairModalOpen] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  useImperativeHandle(ref, () => ({
    scrollIntoView: (option: boolean | ScrollIntoViewOptions | undefined) => {
      if (cardRef.current) {
        cardRef.current.scrollIntoView(option);
      }
    },
  }));

  const taskId =
    timeLineEvent.properties['TaskId'] ??
    `${dataSource}_${timeLineEvent.event}`;

  const fetchServiceTask = async (): Promise<Task> => {
    const task = (await api.fetchJson(
      `/dwar/api/almanac/ServiceTask/getServiceTask?serviceTaskId=${taskId}`
    )) as Task;
    if (
      dataSource.startsWith('Siebel') &&
      (task === undefined || task.taskNumber === undefined)
    ) {
      dataSource = 'OFS';
      return (await api.fetchJson(
        `/dwar/api/almanac/ServiceTask/getServiceTask?serviceTaskId=${dataSource}_${timeLineEvent.event}`
      )) as Task;
    } else {
      return task;
    }
  };

  useEffect(() => {
    if (timeLineEvent.type === TimeLineEventType[TimeLineEventType.Repair]) {
      (async () => {
        const raId = timeLineEvent.event;
        if (raId === 'Custom') {
          const moduleId = timeLineEvent.properties['moduleId'];
          if (moduleId) {
            const moduleInfo = await db.modules.get(parseInt(moduleId));
            if (moduleInfo) {
              setEvent(`RA: ${raId} - ${moduleInfo.ModuleName}`);
            }
          }
        } else {
          const raRecord = await db.raactions.get(parseInt(raId));
          if (raRecord) {
            const moduleInfo = await db.modules.get(raRecord.ModuleId);
            if (moduleInfo) {
              setEvent(`RA: ${raId} - ${moduleInfo.ModuleName}`);
            }
          }
        }
      })();
    }
  }, [
    timeLineEvent.type,
    timeLineEvent.event,
    timeLineEvent.properties,
    timeLineEvent.id,
  ]);

  const handleClick = () => {
    switch (timeLineEvent.type) {
      case TimeLineEventType[TimeLineEventType.EFC]:
      case TimeLineEventType[TimeLineEventType.ServiceInsight]:
        (async () => {
          const efc = await db.efcs
            .where('EfcCodeStr')
            .equalsIgnoreCase(timeLineEvent.event)
            .first();
          if (efc) {
            navigateToEfc(efc.Id.toString());
          }
        })();
        break;
      case TimeLineEventType[TimeLineEventType.Repair]:
        (async () => {
          const raId = timeLineEvent.event;
          if (raId === 'Custom') {
            setCustomRepairModalOpen(true);
          } else {
            const efcId = timeLineEvent.properties['efcId'];
            if (raId && efcId) {
              navigateToRepairAction(raId, parseInt(efcId), false);
            }
          }
        })();
        break;
      case TimeLineEventType[TimeLineEventType.Part]:
        navigateToPart(timeLineEvent.event);
        break;
      case TimeLineEventType[TimeLineEventType.Task]:
        fetchServiceTask()
          .then((serviceTaskResult) => {
            setServiceTaskData(serviceTaskResult);
          })
          .catch(async (error) => {
            logging(
              'warn',
              'Unable to retrieve service task. Using offline data',
              error,
              false,
              null
            );
            let dbServiceTaskResult = await db.tasks.get(`${taskId}`);
            if (
              dataSource.startsWith('Siebel') &&
              (dbServiceTaskResult === undefined ||
                dbServiceTaskResult.taskNumber === undefined)
            ) {
              dataSource = 'OFS';
              dbServiceTaskResult = await db.tasks.get(
                `${dataSource}_${timeLineEvent.event}`
              );
              setServiceTaskData(dbServiceTaskResult as Task);
            } else {
              setServiceTaskData(dbServiceTaskResult as Task);
            }
          });
        setServiceTaskModalOpen(true);
        break;
    }
    onTimelineClick();
  };

  const calcTimeStamps = (timeStamps: modifiedTimeStamps[]) => {
    return timeStamps.map((timeStampItem) => {
      const { diffInDays, hours, minutes, weekMonthDay } = calculateTime(
        timeStampItem.value
      );
      return {
        status: timeStampItem.status,
        value: `${diffInDays} days ${hours}:${minutes}  ${weekMonthDay}`,
        count: timeStampItem?.count,
      };
    });
  };
  const [softwareAllTimeStamp, setSoftwareAllTimeStamp] = useState<
    string | undefined
  >(undefined);
  const [timeStamps, setTimeStamps] = useState<modifiedTimeStamps[]>([]);
  useEffect(() => {
    if (timeLineEvent?.timestamps) {
      if (
        timeLineEvent.type ===
          TimeLineEventType[TimeLineEventType.SoftwareInventory] &&
        currentMenu === t('All')
      ) {
        const { diffInDays, hours, minutes, weekMonthDay } = calculateTime(
          timeLineEvent.timestamps[timeLineEvent.timestamps.length - 1]?.value
        );
        const { hours: lastHours, minutes: lasMinutes } = calculateTime(
          timeLineEvent.timestamps[0]?.value
        );
        if (
          timeLineEvent.timestamps[timeLineEvent.timestamps.length - 1]
            ?.value &&
          timeLineEvent.timestamps[0]?.value
        ) {
          setSoftwareAllTimeStamp(
            `${diffInDays} days ${hours}:${minutes} - ${lastHours}:${lasMinutes}  ${weekMonthDay}`
          );
        } else if (timeLineEvent.timestamps[0]?.value) {
          setSoftwareAllTimeStamp(
            `${diffInDays} days ${lastHours}:${lasMinutes}  ${weekMonthDay}`
          );
        }
      } else {
        const modifiedStamps = calcTimeStamps(timeLineEvent.timestamps);
        setTimeStamps(modifiedStamps);
      }
    }
  }, [currentMenu, t, timeLineEvent.timestamps, timeLineEvent.type]);

  let raFeedback = '';

  if (timeLineEvent.type === TimeLineEventType[TimeLineEventType.Repair]) {
    raFeedback = timeLineEvent.properties['feedback'];
  }
  const { fixedIcon, fixedIconColor } = useGetRAPerformedIcon(raFeedback);

  return (
    <>
      {serviceTaskData && (
        <ServiceTaskModal
          serviceTask={serviceTaskData}
          isOpen={isServiceTaskModalOpen}
          handleClose={() => setServiceTaskModalOpen(false)}
        />
      )}
      {timeLineEvent &&
        timeLineEvent.type === TimeLineEventType[TimeLineEventType.Repair] &&
        timeLineEvent.event === 'Custom' && (
          <CustomRepairModal
            timelineEvent={timeLineEvent}
            isOpen={isCustomRepairModalOpen}
            handleClose={() => setCustomRepairModalOpen(false)}
          />
        )}

      <div
        onClick={handleClick}
        className="flex scroll-mt-20 scroll-mb-20"
        ref={cardRef}
        id={timeLineEvent.id}
      >
        {timeLineEvent &&
          timeLineEvent.type === TimeLineEventType[TimeLineEventType.Task] &&
          timeLineEvent.sharedStatus && (
            <div className="flex w-6 shrink-0 items-center justify-center rounded-l-lg bg-steel">
              <TimelineTaskIcon taskStatus={timeLineEvent.sharedStatus} />
            </div>
          )}
        {timeLineEvent &&
          (timeLineEvent.type === TimeLineEventType[TimeLineEventType.EFC] ||
            timeLineEvent.type ===
              TimeLineEventType[TimeLineEventType.ServiceInsight]) && (
            <div className="flex w-6 shrink-0 items-center justify-center rounded-l-lg bg-dnred">
              <Icon
                is={TerminalOutlinedIcon}
                size="1rem"
                colorClass="text-white"
              />
            </div>
          )}
        {timeLineEvent &&
          timeLineEvent.type === TimeLineEventType[TimeLineEventType.Part] && (
            <div className="flex w-6 shrink-0 items-center justify-center rounded-l-lg bg-cobalt">
              <Icon
                is={ConstructionOutlinedIcon}
                size="1rem"
                colorClass="text-white"
              />
            </div>
          )}
        {timeLineEvent &&
          timeLineEvent.type ===
            TimeLineEventType[TimeLineEventType.Repair] && (
            <div className="flex w-6 shrink-0 items-center justify-center rounded-l-lg bg-purple">
              <Icon
                is={BuildCircleOutlinedIcon}
                size="1rem"
                colorClass="text-white"
              />
            </div>
          )}
        {timeLineEvent &&
          timeLineEvent.type ===
            TimeLineEventType[TimeLineEventType.SoftwareInventory] && (
            <div className="flex w-6 shrink-0 items-center justify-center rounded-l-lg bg-yellow">
              <Icon
                is={LayersOutlinedIcon}
                size="1rem"
                colorClass="text-white"
              />
            </div>
          )}

        <div className=" flex w-full min-w-0 items-center justify-between  rounded-r-lg border-2 border-antartica bg-white p-2">
          <div className="flex w-10/12 flex-grow  flex-col items-start self-start">
            <div className="text-lg font-bold">
              {timeLineEvent.type ===
              TimeLineEventType[TimeLineEventType.Repair]
                ? event
                : timeLineEvent.event}
            </div>
            <div className="flex max-w-full items-start justify-between">
              <div className="overflow-ellipsis">
                {timeLineEvent.description}
              </div>
            </div>
            {TimeLineEventType[TimeLineEventType.SoftwareInventory] ===
              timeLineEvent.type && currentMenu === t('All') ? (
              <div className=" font-bold leading-none text-steel">
                {softwareAllTimeStamp}
              </div>
            ) : (
              <TimelineCollapsibleManager
                timeStamps={timeStamps}
                type={timeLineEvent.type}
              />
            )}
          </div>
          {timeLineEvent.type ===
            TimeLineEventType[TimeLineEventType.Repair] && (
            <div className={`h-6 w-6 ${fixedIconColor}`}>
              <Icon
                is={fixedIcon}
                size=".75rem"
                colorClass="text-white"
                className="mt-0.4"
              />
            </div>
          )}
          {timeLineEvent.type ===
            TimeLineEventType[TimeLineEventType.ServiceInsight] && (
            <img
              src="./lightBulbBrain.png"
              width={30}
              className="h-[30px]"
              alt=""
            />
          )}
          {isClickable && <Icon is={ChevronRightOutlinedIcon} />}
        </div>
      </div>
    </>
  );
}
export default forwardRef(TimelineEvent);

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useLogging from '../../hooks/useLogging';
import LogParams from '../../Models/LogParams';
import { usePage } from '../../PageProvider';
import { LogType } from '../../enums/LogType';
import CustomTable from '../../components/custom-table';
import db from '../../index-db';
import DSAFeedback from '../../Models/DSAFeedback';
import { FeedbackStatus } from '../../Models/Enumerations';
import SectionHeading from '../../components/SectionHeading';
import GenericModalSheet from '../../components/GenericModalSheet';
import DetailFeedback from './DetailFeedback';
import { useApi } from '../../acfs-apis/dwar-api-provider';
import Module from '../../Models/Module';

interface IFeedbackStatusData {
  Id: number;
  Type: string;
  ModuleName: string;
  Status: string;
  SubmitTime: string;
  Comments: string;
}

interface IFeedbackCommentsForStatus {
  feedbackStatus: string;
  comments: string;
}

function ViewFeedback() {
  const { t } = useTranslation();
  const logging = useLogging();
  const [feedbackData, setfeedbackData] = useState<IFeedbackStatusData[]>([]);
  const [dsaFeedbackData, setDsaFeedbackData] = useState<DSAFeedback[]>([]);
  const [selectedFeedback, setSelectedFeedback] = useState<DSAFeedback>();
  const [isBottomsheetOpen, setIsBottomsheetOpen] = useState(false);

  const api = useApi();

  const onSheetClose = () => {
    setIsBottomsheetOpen(false);
  };

  const fetchDSAFeedback = async (): Promise<DSAFeedback[]> => {
    return (await api.fetchJson(
      `/dwar/api/knowledgebase/Feedback/GetFeedback`
    )) as DSAFeedback[];
  };

  const SetFeedbackDisplayData = async (
    dsaFeedback: DSAFeedback[],
    modules: Module[]
  ) => {
    const feedbackStatusData = dsaFeedback.map((data) => {
      let feedback = {} as IFeedbackStatusData;
      let currentDate = new Date(Date.now());
      let timestamp = new Date(`${data.submitTime}Z`);

      const diffInTime = currentDate.getTime() - timestamp.getTime();
      const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

      const hours =
        timestamp.getHours() < 10
          ? `0${timestamp.getHours()}`
          : timestamp.getHours().toString();
      const minutes =
        timestamp.getMinutes() < 10
          ? `0${timestamp.getMinutes()}`
          : timestamp.getMinutes().toString();

      const weekMonthDay = timestamp
        .toLocaleDateString(undefined, {
          month: 'short',
          day: 'numeric',
          weekday: 'short',
        })
        .split(',')
        .join(' ');

      feedback.Id = data.id;
      feedback.Type = GetFeedbackType(data);
      feedback.ModuleName =
        modules.filter((mod) => mod.Id === data.odysseyModuleId)[0]
          ?.ModuleName ?? '';
      const feedbackCommentsForStatus = GetFeedbackCommentsForStatus(data);
      feedback.Status = feedbackCommentsForStatus.feedbackStatus;
      feedback.SubmitTime = `${diffInDays} days ago ${hours}:${minutes} ${weekMonthDay}`;
      feedback.Comments = feedbackCommentsForStatus.comments;

      return feedback;
    });
    setfeedbackData(feedbackStatusData);
  };

  useEffect(() => {
    (async () => {
      let dsaFeedback: DSAFeedback[] = [];
      dsaFeedback = await db.dsaFeedback.toArray();
      setDsaFeedbackData(dsaFeedback);
      const modules = await db.modules.toArray();
      await SetFeedbackDisplayData(dsaFeedback, modules);

      try {
        dsaFeedback = await fetchDSAFeedback();
        await db.dsaFeedback.clear();
        await db.dsaFeedback.bulkPut(dsaFeedback);
      } catch (error) {
        logging(
          'warn',
          'Unable to retrieve DSA Feedback data. Falling back to offline data',
          error,
          false,
          null
        );
      }
      setDsaFeedbackData(dsaFeedback);
      await SetFeedbackDisplayData(dsaFeedback, modules);
    })();
  }, []);

  const GetFeedbackType = (data: DSAFeedback): string => {
    if (data.raId) {
      return 'Repair';
    } else if (data.efcCodeId) {
      return 'EFC';
    } else {
      return 'General';
    }
  };

  const GetFeedbackCommentsForStatus = (
    data: DSAFeedback
  ): IFeedbackCommentsForStatus => {
    let feedbackCommentsForStatus = {} as IFeedbackCommentsForStatus;
    switch (data.feedbackState) {
      case 0:
        feedbackCommentsForStatus.feedbackStatus =
          FeedbackStatus[FeedbackStatus.New];
        feedbackCommentsForStatus.comments = '';
        break;
      case 1:
        feedbackCommentsForStatus.feedbackStatus =
          FeedbackStatus[FeedbackStatus.Processed];
        feedbackCommentsForStatus.comments =
          data.reviewComments && data.reviewComments.length > 50
            ? `${data.reviewComments.substring(0, 49)}...`
            : data.reviewComments;
        break;
      case 2:
        feedbackCommentsForStatus.feedbackStatus =
          FeedbackStatus[FeedbackStatus.Cancelled];
        feedbackCommentsForStatus.comments = '';
        break;
      case 3:
        feedbackCommentsForStatus.feedbackStatus =
          FeedbackStatus[FeedbackStatus.WaitingForFieldFeedback];
        feedbackCommentsForStatus.comments =
          data.fieldFeedbackRequestComments &&
          data.fieldFeedbackRequestComments.length > 50
            ? `${data.fieldFeedbackRequestComments.substring(0, 49)}...`
            : data.fieldFeedbackRequestComments;
        break;
      case 4:
        feedbackCommentsForStatus.feedbackStatus =
          FeedbackStatus[FeedbackStatus.WaitingForLevel4Review];
        feedbackCommentsForStatus.comments =
          data.level4ReviewComments && data.level4ReviewComments.length > 50
            ? `${data.level4ReviewComments.substring(0, 49)}...`
            : data.level4ReviewComments;
        break;
      case 5:
        feedbackCommentsForStatus.feedbackStatus =
          FeedbackStatus[FeedbackStatus.InProcessByLevel4];
        feedbackCommentsForStatus.comments =
          data.inProcessByLevel4ReviewComments &&
          data.inProcessByLevel4ReviewComments.length > 50
            ? `${data.inProcessByLevel4ReviewComments.substring(0, 49)}...`
            : data.inProcessByLevel4ReviewComments;
        break;
    }
    return feedbackCommentsForStatus;
  };

  useEffect(() => {
    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.ViewFeedback,
      },
    ];
    logging('info', '', '', false, logParams);
  }, []);

  usePage(
    () => ({
      getTitle: () => t('Feedback'),
      belongsToNavBarItem: 'Menu',
      showBackButton: true,
    }),
    [t]
  );

  return (
    <div className="ml-4 mr-4">
      <div className="mt-2 mb-2 items-center justify-between">
        <SectionHeading title={`${t('Feedback Status')}`} withMargins={false} />
      </div>
      <CustomTable
        data={feedbackData}
        columnOptions={{
          size: 80,
        }}
        hookOptions={{
          enableColumnFilters: true,
          enableSorting: true,
          initialState: {
            showColumnFilters: true,
            columnVisibility: { Id: false },
          },
          muiTableBodyRowProps: ({ row }) => ({
            onClick: (event) => {
              const feedbackId = row.getValue('Id');
              setIsBottomsheetOpen(true);
              const filteredFeedback = dsaFeedbackData.filter(
                (feedback) => feedback.id === feedbackId
              );
              if (filteredFeedback.length) {
                setSelectedFeedback(filteredFeedback[0]);
              }
            },
            sx: {
              cursor: 'pointer',
            },
          }),
        }}
      />
      <GenericModalSheet
        isBottomsheetOpen={isBottomsheetOpen}
        onSheetClose={onSheetClose}
      >
        {selectedFeedback && <DetailFeedback feedback={selectedFeedback} />}
      </GenericModalSheet>
    </div>
  );
}
export default ViewFeedback;

const translation = {
  'You switched to {{ language }}': 'Bạn chuyển đổi tới {{language}}',
  'Vietnamese': 'Tiếng Việt',
  'Home': 'Trang chủ',
  'Search': 'Tìm Kiếm',
  'Saved': 'Đã Lưu',
  'Me': 'Tôi',
  'Menu': 'Danh sách',
  'English': 'Tiếng Anh',
  'AllConnect Field Support': ' Kết nối dữ liệu hiện trường',
  'Settings': 'Cài đặt',
  'Language': 'Ngôn ngữ',
  'Saved Items': 'Những mục đã lưu',
  'My tasks': 'Những nhiệm vụ của chúng tôi',
  'Parts': 'Danh sách linh kiện',
  'Saved items': 'Những mục đã lưu',
  'Notifications': 'Những thông báo',
  'Help': 'Giúp đỡ',
  'Feedback': 'Nhận xét',
  'Feedback Status': 'Trạng thái phản hồi',
  'Repair Feedback': 'Phản hồi sửa chữa',
  'EFC Feedback': 'EFC Nhận xét',
  'General Feedback': 'Phản hồi chung',
  'Module Name': 'Tên mô-đun',
  'EFC Code': 'Mã EFC',
  'Your Feedback': 'Phản hồi của bạn',
  'Field Feedback Requested': 'Đã yêu cầu phản hồi trường',
  'Level 4 to Review': 'Cấp độ 4 để xem xét',
  'In Process by Level 4': 'Đang xử lý theo cấp độ 4',
  'Processed': 'Đã xử lý',
  'Logout': ' Đăng xuất',
  'Add to equipment list': 'Thêm vào danh sách thiết bị',
  'Download for offline': 'Tải offline',
  'Repair Action': 'Hành động khắc phục',
  'Repair action': 'Hành động khắc phục',
  'Part': 'Linh kiện',
  'Refreshed {{time}} min ago': 'Làm mới {{time}} phút trước',
  'Recently viewed': 'Đã xem gần đây ',
  'View all': 'Xem tất cả',
  'Clear All': ' Xóa tất cả ',
  'Expand all': 'Mở rộng tất cả các phần',
  'Collapse all': 'Thu gọn tất cả',
  'Search results': 'Kết quả tìm kiếm',
  'Repairs': 'Sữa chữa',
  'Downloading content...': 'Tải xuống nội dung',
  'Bookmarks': 'Danh sách đã đánh dấu',
  'Downloads': 'Tải xuống',
  'Recent': 'Gần đây',
  'Recent codes': 'Mã gần đây',
  'Saved codes': 'Mã đã lưu ',
  'Updates': 'Cập nhật',
  'Clear': ' Xóa',
  'Updated Content for {{item}}.': 'Cập nhật nội dung cho {{ item}}',
  '{{item}} has been saved': '{{item}} đã được lưu  ',
  '{{item}} could not be saved': '{item}} không được lưu ',
  '{{item}} has been removed from saved': '{{item}} đã bị xóa từ mục đã lưu',
  '{{item}} could not be removed from saved':
    '{{item}} không bị xóa từ mục đã lưu',
  'Difficulty': 'Độ khó',
  'Repair time': 'Thời gian sữa chữa',
  'min_one': ' 1 phút',
  'min_other': '{{count}} phút ',
  'min_zero': '0 phút',
  'Rating': 'Đánh giá',
  'Thank you for the feedback': 'Cảm ơn cho sự phản hồi',
  'Not relevant': 'Không liên quan',
  'Relevant': 'Liên quan',
  'Fixed my issue': ' Đã khắc phục sự cố',
  'Leave feedback': 'Để lại phản hồi',
  'Save item': 'Mục đã lưu',
  'Low': 'Mức thấp',
  'Medium': 'Mức trung bình',
  'High': 'Mức cao',
  'This was relevant info': 'Thông tin có liên quan',
  'This was not relevant info': 'Thông tin không liên quan',
  'Add to saved items': 'Thêm vào các mục đã lưu',
  'Remove from downloads': 'Xóa khỏi tệp tải xuống ',
  'Search AllConnect Field Support': 'Tìm kiếm hỗ trợ trường kết nối dữ liệu',
  'Filters': 'Bộ lọc',
  'Page not found': 'Trang không tìm thấy',
  'Return home': ' Trở về trang chủ',
  'Clear all bookmarks': 'Xóa tất cả các đánh dầu trang',
  'Search all EFC codes': 'Tìm kiếm tất cả mã EFC',
  'Search all parts': 'Tìm kiếm tất cả linh kiện',
  'Results ({{resultsCount}})': 'Kết quả ({{resultsCount}}',
  '{{item}} is already saved': '{{item}} đã được lưu',
  'Details': 'Chi tiết',
  'Equipment': 'Thiết bị',
  'Notes': 'Ghi chú',
  'Preventive Insights': 'Thông tin chi tiết về phòng ngừa',
  'Service Insights': 'Thông tin chi tiết về dịch vụ',
  'Equipment Information': 'Thông tin thiết bị',
  'Equipment ID': 'ID thiết bị',
  'Model': 'Model',
  'Terminal ID': 'ID đầu cuối',
  'Device serial number': 'Số serial number của thiết bị',
  'EFC codes': 'Mã EFC',
  'Steps': 'Các bước',
  'Module content': 'Nội dung Module',
  'Repair action content': 'Nội dung hành động khắc phục',
  'Fixed issue': 'Đã khắc phục sự cố',
  'Part Number {{partId}} copied to the clipboard':
    'Mã linh kiện {{partId}} được sao chép vào clipboard',
  'Relationship tree': 'Mối liên hệ',
  'Potential substitutions': 'Thay thế khác',
  'Loading': 'Đang tải',
  'Removed from saved searches': 'Đã xóa khỏi tìm kiếm đã lưu',
  'Added to saved searches': 'Đã thêm vào tìm kiếm đã lưu',
  'All bookmarks cleared': 'Tất cả danh sách dấu trang đã được xóa',
  'No bookmarks to clear': 'Không có danh sách trang được xóa',
  'All recent items cleared': 'Tất cả mục gần đây đã bị xóa',
  'No recent items to clear': 'Không mục gần đây để xóa ',
  'You have no recently view history.': 'Bạn không có lịch sử xem gần đây',
  'Switching language is done in 2 phases. The static data such as labels and headings will be switched immediately. The content data requires an online connection and can take up to a minute or more depending on your connection to refresh the new language choice.  Until content is downloaded the app will continue to show the previous language for content.':
    'Chuyển đổi ngôn ngữ được thực hiện trong 2 giai đoạn. Dữ liệu như nhãn và tiêu đề sẽ được chuyển đổi ngay lập tức.Dữ liệu nội dung yêu cầu kết nối trực tuyến và có thể mất tối đa một phút hoặc hơn tùy thuộc vào kết nối của bạn để làm mới lựa chọn ngôn ngữ mới.Cho đến khi nội dung được tải xuống, ứng dụng sẽ tiếp tục hiển thị ngôn ngữ trước đó cho nội dung',
  'Signing you in': 'Đăng nhập ',
  'Signing you out': 'Đăng xuất',
  'Oops': 'Oops',
  'Switching language to {{ language }}':
    'Chuyển đổi ngôn ngữ tới {{ language}}',
  'Data has been fetched in': 'Dữ liệu đã được tìm nạp trong',
  'Failed to fetch data in': 'Không thể nạp dữ liệu vào',
  'No results': 'Không kết quả',
  'URL': 'URL',
  'User Name': 'Danh sách',
  'ACFS App': 'Danh sách ACFS',
  'ACFS UI': 'ACFS UI',
  'Offline Data': 'Dữ liệu ngoại tuyến',
  'Info': 'Thông tin',
  'Repair': 'Sữa chữa',
  'RepairActionCount 1': '1 hành động sữa chữa ',
  'RepairActionCount {{count}}': '{{count}} Hành động sữa chữa',
  'RepairActionCount 0': '0 hành động sữa chữa',
  'All': 'Tất cả',
  'Sync': 'Đồng bộ',
  'Last 30 days': '30 ngày trước',
  'Not yet implemented': 'Chưa được thực hiện',
  'Categories': 'Loại',
  'Repair actions': 'Hành động sữa chữa',
  'Module': 'Module',
  'Service Manuals': 'Hướng dẫn sử dụng dịch vụ',
  'Service Bulletins': 'Bản tin dịch vụ',
  'FCOs': 'FCO',
  'FAQs': 'FAQ',
  'Training Content': 'Nội dung đào tạo',
  'Your feedback has been sent': 'Phản hồi của bạn đã được gửi đi',
  'Failed to send your feedback': 'Không thể gửi phản hồi của bạn',
  'Comments are required': 'Bình luận là được yêu cầu',
  'Comments should not be more than 100 characters':
    'Bình luận không quá 100 ký tự',
  'Max {{numOfPhotos}} photos can be uploaded':
    'Ảnh tối đa {{numOfPhotos}} có thể được tải lên',
  'Add this Task - {{taskNumber}}': 'Thêm task - {{taskNumber}}',
  "Other '{{inputValue}}'": "Mục khác ' {{inputValue}}'",
  'Other (Type to add) / Blank': 'Mục khác ( Kiểu để thêm)/ Mục trống',
  'General feedback': 'Phản hồi chung',
  'Feedback: EFC code - {{efcCode}}': 'Phản hồi : EFC code - {{efcCode}}',
  'Module: {{moduleName}}': 'Module: {{moduleName}}',
  'Feedback for a specific EFC Code, Repair Action, or Part should be submitted using the feedback option available on those specific entries.':
    'Phản hồi cho EFC code cụ thể này , hành động khắc phục, hoặc linh kiện cần được thay thế sử dụng khác cho EFC này',
  'Corrective action required': 'Hành động đúng yêu cầu để khắc phục',
  'Yes': 'Đúng',
  'No': 'Không',
  'Task number': 'Số Task',
  'Select or Type to add': 'Lựa chọn kiểu sự cố để thêm vào',
  'Comments': 'Ý kiến',
  'Delete': ' Xóa ',
  'Open Camera': 'Mở Cameara',
  'Upto {{maxFiles}} images can be selected':
    'Số lưởng ảnh tối đa có thể chọn {{maxFiles}}',
  'Cancel': 'Thoát',
  'Add': 'Thêm',
  'Add equipment': 'Thêm thiết bị',
  'Serial number': 'Serial number',
  'Scan the equipment code or enter the serial number to view the insights and details for that piece of equipment.':
    'Quét mã thiết bị và nhập số serial number để xem thông tin chi tiết cho phần thiết bị đó',
  'Last synced: {{time}} min ago':
    ' Đồng bộ hóa lần cuối: {{ time }} phút trước',
  'Send': 'Gửi',
  "Task Number must be provided if 'Corrective action required' is Yes":
    'Số task phải được cung cấp nếu \' Yêu cầu hành động khắc phục" là có',
  'Time to fix (minutes)': 'Thời gian để khắc phục (Số phút) ',
  'Time to fix should not be more than 999':
    'Thời gian để phản ồi không lớn hơn 999',
  'Please enter positive numbers only': 'Vui lòng chỉ nhập số dương',
  'Please enter numbers only': 'Vui long lòng chỉ nhập số',
  'Clear filters': 'Xóa bộ lọc',
  'Show results': 'Hiển thị kết quả',
  'Your feedback has been submitted': 'Phản hồi của bạn đã được cập nhật',
  'This EFC is related to an enrichment link that is currently active.Please let us know if the enrichment is relevant (appropriate action to perform).If you want to leave additional feedback select "Leave feedback"':
    'EFC này liên quan tới liên kết khác phục đã được hoạt động. Vui lòng cho chúng tôi biết nếu liên kết khắc phục này là liên quan ( hành động đúng để thực hiện) ',
  'Products': 'Sản phẩm',
  'Product Number {{productId}} copied to the clipboard':
    'Số sản phẩm {{productId}} được sao chép vào trường nhớ tạm',
  'View Groups': 'Xem danh sách nhóm',
  'Search all products': 'Tìm kiếm tất cả sản phẩm',
  'Current': 'Hiện tại',
  'History': 'Lịch sử',
  'No equipment data found for serial number {{number}}.':
    'Không tìm thấy dữ liệu thiết cho serial number này {{ number}}',
  'Unable to retrieve equipment data for the serial number {{number}}.':
    'Không thể truy xuất dữ liệu thiết cho số serial number {{number}}',
  'Unable to retrieve equipment data. Falling back to offline data.':
    'Không thể truy xuất dữ liệu thiết bị.Quay trở lại dữ liệu ngoại tuyến offline ',
  'Unable to retrieve service task. Falling back to offline data.':
    'Không thể truy truy xuất được task dịch vụ. Quay trở lại dữ liệu ngoại tuyến offline',
  'Please wait while data is being loaded in {{language}}':
    'Vui lòng đợi trong khi dữ liệu được thêm vào {{language}}} ',
  'Errors': 'Lỗi',
  'Tasks': 'Nhiệm vụ',
  'History Parts': 'Lịch sử linh kiện',
  'Unable to retrieve Service Task history.':
    'Không thể truy xuất lịch sử của số Task',
  'Recommended Parts': 'Linh kiện khuyến nghị',
  'Service Task Details': 'Chi tiết số task',
  'Task Number': 'Số task',
  'Task Status': 'Tình trạng task',
  'Problem': 'Vấn đề',
  'Task Type': 'Kiểu task',
  'Customer': 'Khách hàng',
  'Effective Date': 'Ngày có hiệu lực ',
  'Device Description': 'Mô tả thiết bị',
  'Device Serial Number': 'Số serial number thiết bị',
  'EndPoint Id': 'ID thiết bị',
  'Service Task Notes {{length}}.': 'Lưu ý ở task dịch vụ {{length}}.',
  'Part {{partId}} cannot be found in DNSpares data':
    'Không thể tìm thấy linh kiệm trong dữ liệu DNspares {{partId}}',
  'Number': 'Số',
  'Site Number': 'Số site',
  'Address': 'Địa chỉ',
  'Contact Date': 'Ngày liên hệ',
  'Contact Name': 'Tên liên hệ',
  'Contact Email': 'Email liên hệ',
  'Contact Phone': 'Số điện thoại liên hệ',
  'Contract Hours': 'Số giờ liên hệ',
  'Site Messages:': 'Thông tin site:',
  'Type': 'Kiểu',
  'Product': 'Sản phẩm',
  'Desc': 'Mô tả',
  'Task Number should not be more than 30 characters':
    'Số lượng nhiệm vụ không được nhiều hơn 30 ký tự',
  'Select Photos': 'Số lượng ảnh chọn',
  'Session Expired': 'Phiên đã hết hạn',
  'The current session has expired while offline. Please bring the device back online and select the Login button below to perform the login and take you back to the home screen.':
    'Phiên hiện tại đã hết hạn khi ngoại tuyến offline. Bạn vui lòng để thiết bị online và chọn nút đăng nhập Login bên dưới để thực hiện đăng nhập và đưa bạn trở lại màn hình chính',
  'Login': 'Đăng nhập',
  'Please provide your feedback in order to improve the content in the ACFS Site not related to a specific EFC or Repair Action.':
    'Vui lòng cung cấp phản hồi để cải thiện nội dung trong ACFS không liên quan đến một EFC cụ thể hoặc hành động sữa chữa',
  'Please provide your feedback in order to improve the content that follows this EFC Code.':
    'Vui lòng cung cấp phản hồi của bạn để cải thiện nội dung theo EFC này',
  'Is this Repair Action missing anything? For example, should the star rating or time to fix be adjusted? Please provide your feedback in order to improve the content of this specific Repair Action and its association with the EFC that prompted this.':
    'Hành động sữa chữa này có thiếu thứ gì không ? Ví dụ:có nên điều chỉnh xếp hạng hoặc thời gian sữa chữa không ? Vui lòng cung cấp phản hồi của bạn để cải thiện nội dung của hành động sữa chữa cụ thể và mối liên hệ của nó với EFC đã thúc đẩy điều này ',
  'Select "Yes" if you are recommending that a change needs to be made by the administrators.':
    'Lựa chọn "Yes" nếu bạn đề nghị rằng quản trị viên nên cần thay đổi ',
  'Feedback is not mandatory.Please use English if possible. Feedback is used to improve the content in ACFS. Feedback can be shared with management to ensure quality. Administrators may contact individuals for additional information to help clarify any information needed to improve content or other business purposes.':
    'Phản hồi là không bắt buộc.Sử dụng Tiếng Anh nếu có thể. Phản hồi được sử dụng để cải thiện nội dung trong ACFS. Phản hồi có thể được chia sẻ với ban quản lý để đảm bảo chất lượng. Quản trị viên có thể liên hệ với các cá nhân để biết thêm thông tin nhằm giúp làm rõ bất kỳ thông tin nào cần thiết để cải thiện nội dung hoặc các mục đích kinh doanh khác ',
  'OfflineMessage':
    'Khi ACFS ở chế độ ngoại tuyến, có một số nội dung sẽ không khả dụng do bản chất của nội dung, tần suất cập nhật và hạn chế lưu trữ trong thiết bị cuối .Do những ...',
  'OfflineMessage1':
    'Trên trang chủ . Phần task chỉ lựa chọn các task đang mở có sẵn trong thiết bị',
  'OfflineMessage2':
    'Trên các trang liên quan ( thiết bị, chi tiết,linh kiện), thiết bị và dịch vụ dữ liệu sẽ được hiển thị từ dữ liệu có sẵn trong thiết bị ',
  'OfflineMessage3':
    'Trang ghi chú sẽ chỉ hiển thị các ghi chú về các tác vụ dịch vụ đang mở của người dùng khi có sẵn trong thiết bị tương quan với thiết bị',
  'OfflineMessage4':
    'Chức năng thêm thiết bị trên ME page và equipment page không thể được tìm nạp dựa trên số se-ri do người dùng cung cấp ',
  'Feedback: Repair # {{repairActionId}}':
    'Phản hồi : Khắc phục # {{repairActionId}}',
  'Training': 'Đào tạo',
  'General Help': 'Trợ giúp chung',
  'Offline Disclaimer': 'Tuyên bố từ chối trách nhiệm ngoại tuyến offline',
  'Preferences': 'Lựa chọn',
  'Nothing here yet': 'Chưa có gì ở đây',
  'Unable to retrieve tasks. Falling back to offline data.':
    'Không thể truy xuất được nhiệm vụ. Quay trở lại dữ liệu offline',
  'Category': 'Loại',
  'Family': 'Family',
  'Skill level required': 'Yêu cầu trình độ kỹ năng',
  'Sort by': 'Sắp xếp theo',
  'Subsystem': 'Hệ thống nhỏ',
  'Recent records': 'Ghi nhận gần đây',
  'Systems': 'Hệ thống',
  'Task - {{taskNumber}}': 'Nhiệm vụ - {{ Số nhiệm vụ}}',
  'Add New': 'Thêm mới',
  'Everywhere': 'Mọi nơi',
  'Keywords': 'Từ khóa',
  'The details page contains content only when there is an active call assigned to the current user for this equipment.':
    'Trang chi tiết chứa nội dung chỉ khi nơi các call chủ động được phân bố tới người dùng cho thiết bị này',
  'OfflineMessage5':
    'Các hình ảnh trong nội dung của ứng dụng giống như hình ảnh EFC,hình ảnh các bước hành động sữa chữa, hình ảnh linh kiện sẽ không được hiển thị nếu người dùng ngoại tuyến',
  'Module(EFC only)': 'Mô đung ( chỉ EFC)',
  'German': 'Tiếng Đức',
  'Francias': 'Tiếng Pháp',
  'Polish': 'Tiếng Ba lan',
  'Espanol': 'Tiếng Tây Ban Nha',
  'Indonesian': 'Tiếng Indonesia',
  'Chinese': 'Tiếng Trung Quốc',
  'Thai': 'Tiếng Thái',
  'Portuguese': 'Tiếng Bồ Đào Nha',
  'Turkish': 'Tiếng Thổ Nhĩ Kỳ',
  'If no stock, replaces': 'Nếu không có hàng, thay thế',
  'If no stock, replaced by': 'Nếu không có hàng, thay thế bằng',
  'Replaces': 'Thay thế',
  'Replaced by': 'Thay thế bởi',
  'Repair Id': 'ID sữa chữa',
  'View Child Ids': 'Xem ID Child',
  'Device Id': 'ID thiết bị',
  'Equipment with serial number {{number}} added Succesfully.':
    'Thiết bị với số sê-ri {{number}} được thêm thành công.',
  'mfg': 'Số sản xuất từ nhà máy',
  'Reporting System': 'Báo cáo hệ thống Trân trọng',
  'Update': 'cập nhật',
  'No Endpoint': 'Không có thiết bị đầu cuối',
  'Repair Action performed': 'Hành động sữa chữa được thực hiện',
  'Update: User names are now visible in task history. You’ll see names of users, and others will see yours. This change helps identify previous users, making it easier to contact them for further information if needed.':
    'Cập nhật: Tên người dùng hiện được hiển thị trong lịch sử công việc. Bạn sẽ thấy tên của người dùng khác, và người khác cũng sẽ thấy tên của bạn. Thay đổi này giúp xác định các người dùng trước đó, làm cho việc liên hệ với họ để có thông tin thêm dễ dàng hơn nếu cần.',
  'The ACDE Knowledgebase content is currently being downloaded to this device.  We apologize for the inconvenience.  The page will automatically redirect when the content load is complete.':
    'Nội dung cơ sở kiến thức ACDE hiện đang được tải xuống thiết bị này.  Chúng tôi xin lỗi vì sự bất tiện này.  Trang sẽ tự động chuyển hướng khi quá trình tải nội dung hoàn tất.',
  'We apologize for the inconvenience.  The link used is not a valid location in ACFS.':
    'Chúng tôi xin lỗi vì sự bất tiện này.  Liên kết được sử dụng không phải là vị trí hợp lệ trong ACFS.',
  'The selected endpoint on the Repair Action Page does not match one of the endpoint of the active service task assigned. Select OK to continue. Cancel to change.':
    'Danh sách ATM được chọn trên Trang Hành động Sửa chữa không khớp với một trong các điểm cuối của tác vụ dịch vụ hiện tại được phân công. Chọn OK để tiếp tục. Hủy để thay đổi.',
  'Select “Yes” if you are recommending that a new Repair Action needs to be created by the administrators.':
    'Chọn "Có" nếu bạn đang đề xuất rằng quản trị viên cần tạo hành động Sửa chữa mới.',
  'Select this repair action template to specify the action completed':
    'Chọn mẫu hành động sửa chữa này để chỉ định hành động khắc phục đã hoàn thành',
  'Custom Repair Performed': 'Sửa chữa tùy chỉnh được thực hiện',
  'Submit': 'Đệ trình',
  'Needs reviewed for new RA':
    'Nhu cầu được xem xét cho hành động sữa chữa mới',
  'Title': 'Tiêu đề',
  'Title of repair': 'Tiêu đề sữa chữa',
  'Description of repair': 'Mô tả sữa chữa',
  'Duration to complete repair (minutes)':
    'Thời gian hoàn thành sữa chữa ( số phút)',
  '3 or more Characters are required for the search.':
    '3 hoặc nhiều hơn các ký tự được yêu cầu cho việc tìm kiếm',
  'Title is required': 'Tiêu đề là được yêu cầu',
  'Title should not be more than 200 characters':
    'Tiêu đề không được nhiều hơn 200 ký tự',
  'Details is required': 'Yêu cầu chi tiết',
  'Switching language is done in 2 phases. The static data such as labels and headings will be switched immediately. The content data requires an online connection and can take up to a minute or more depending on your connection to refresh the new language choice. Until content is downloaded the app will continue to show the previous language for content.  Recently viewed and Saved items will be the last to update and can take up to an additional minute.':
    'Chuyển đổi ngôn ngữ được thực hiện theo 2 giai đoạn. Dữ liệu tĩnh như tiêu đề sẽ được chuyển đổi ngay lập tức. Dữ liệu nội dung yêu cầu kết nối trực tuyến và có thể mất tối đa một phút hoặc hơn tùy thuộc vào kết nối của bạn để làm mới lựa chọn ngôn ngữ mới. Cho đến khi nội dung được tải xuống, ứng dụng sẽ tiếp tục hiển thị ngôn ngữ trước đó cho nội dung.  Các mục đã xem và đã lưu gần đây sẽ là mục cuối cùng cập nhật và có thể mất thêm tối đa một phút',
  'No results Or Multiple Results, please narrow your search':
    'Không tìm thấy kết quả, vui lòng thu hẹp tìm kiếm của bạn',
  'Enter the serial number to view the insights and details for that piece of equipment.':
    'Nhập serial number để có thông tin chi tiết về thiết bị đó',
  'Open': 'Mở',
  'Interrupt': 'Tạm dừng',
  'Incomplete': 'Chưa hoàn thành',
  'Closed': 'Đóng',
  'Cancelled': 'Hủy bỏ',
  'Scanned successfully, Redirecting to the scanned link':
    'Quét thành công, chuyển hướng tới link đã được quét',
  'Search Serial number': 'Tìm kiếm Serial Number',
  'No System is Selected': 'Không có thiết nào được chọn',
  'Cancelled Tasks': 'Tác vụ bị hủy',
  'Allowed': 'Cho phép',
  'Not Allowed': 'Không được phép',
  'Open Task UserId': 'Mở tác vụ UserId',
  'Observations': 'Quan sát',
  'GroupName or UserID': 'Tên nhóm hoặc ID người dùng',
  'AI Summary': 'Tóm tắt AI',
  'Chronic / Worst Performer Details':
    'Chi tiết về người biểu diễn mãn tính / tệ nhất',
  'Triage Notes': 'Ghi chú phân loại',
  'Search By': 'Tìm kiếm bởi',
  'Serial Number': 'Số seri',
  'Tag': 'Nhãn',
  'Temporarily add equipment': 'Tạm thời bổ sung thiết bị',
  'System': 'Hệ thống',
  'Machine Name': 'Tên may moc',
  'No data is available': 'Không có dữ liệu có sẵn',
  'Connections': 'Kết nối',
  'Non-Connected': 'Không kết nối',
  'Part Recommendations': 'Khuyến nghị phần',
  'Please wait for the daily update.  The page will automatically refresh when completed.':
    'Vui lòng chờ cập nhật hàng ngày. Trang sẽ tự động làm mới khi hoàn thành.',
  'Install Date': 'Ngày cài đặt',
  'ACDE KB daily update is currently in progress. Part navigation is not available. RepairAction indicator is also not available. Please try again when the content has been downloaded.':
    'ACDE KB cập nhật hàng ngày hiện đang được tiến hành. Điều hướng một phần không có sẵn. Chỉ báo RepairAction cũng không có sẵn. Vui lòng thử lại khi nội dung đã được tải xuống.',
  'No Equipment data available for the Endpoint {{endPointId}}.':
    'Không có dữ liệu Thiết bị nào cho Điểm cuối {{endPointId}}.',
  'Duplicate endpoint has been identified. Historical EFCs may have come from multiple endpoints. Caution should be taken when reviewing the history of this endpoints. Validate that Harvester is setup properly with the correct endpoint registration.':
    'Đã xác định được điểm cuối trùng lặp. EFC lịch sử có thể đến từ nhiều điểm cuối. Cần thận trọng khi xem lại lịch sử của các điểm cuối này. Xác thực Harvester được thiết lập đúng với đăng ký điểm cuối chính xác.',
  'Duplicate Endpoint': 'Điểm cuối trùng lặp',
  'Inventory': 'Hàng tồn kho',
  'Entitlements': 'Quyền lợi',
  'Green': 'Màu xanh lá',
  'Yellow': 'Màu vàng',
  'Red': 'Màu đỏ',
  'Empty': 'Trống',
  'Entitlement is connected and sending data as expected.':
    'Quyền được kết nối và gửi dữ liệu như mong đợi.',
  'Entitlement is connected, but not sending data as expected.  Review the detailed message on the entitlement details page. Click on card to get details on how to resolve.':
    'Quyền được kết nối nhưng không gửi dữ liệu như mong đợi.  Xem lại thông báo chi tiết trên trang chi tiết về quyền được hưởng. Bấm vào thẻ để biết chi tiết về cách giải quyết.',
  'Entitlement is expected, but is not connected and no data.  Click on card to get details on how to resolve.':
    'Quyền được mong đợi nhưng không được kết nối và không có dữ liệu.  Bấm vào thẻ để biết chi tiết về cách giải quyết.',
  'Entitlement does not send information as to the current status. No action is required.':
    'Quyền không gửi thông tin về trạng thái hiện tại. Không cần thực hiện hành động nào.',
  'A disconnected system was previously connected and sending data to ACDE. However, data transmission has now stopped, exceeding the expected timeframe for this connection. Please refer to the ‘Last Data’ field to determine when data was last received.':
    'Một hệ thống bị ngắt kết nối trước đó đã được kết nối và gửi dữ liệu đến ACDE. Tuy nhiên, việc truyền dữ liệu hiện đã dừng lại, vượt quá khung thời gian dự kiến ​​cho kết nối này. Vui lòng tham khảo trường ‘Dữ liệu cuối cùng’ để xác định thời điểm dữ liệu được nhận lần cuối.',
  'Disconnected': 'Đã ngắt kết nối',
  'Software Updated': 'Phần mềm đã được cập nhật',
  'Software': 'Phần mềm',
  'components updated': 'thành phần được cập nhật',
  'component updated': 'thành phần đã được cập nhật',
};

export default translation;

import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

interface Props {
  menuItems: string[];
  activeMenuItem: string;
  onChange: (menu: string) => void;
}

function PageSubMenu({ menuItems, activeMenuItem, onChange }: Props) {
  const { t } = useTranslation();
  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (buttonRef.current?.id === activeMenuItem) {
      buttonRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [activeMenuItem]);

  const activeClasses = 'text-cobalt underline underline-offset-8 decoration-2';
  const inactiveClasses = 'text-steel no-underline';

  return (
    <div className="relative sticky top-0 z-10 flex h-14 overflow-x-auto bg-ice">
      {menuItems.map((menuItem) => (
        <button
          key={menuItem}
          type="button"
          id={menuItem}
          ref={buttonRef}
          className={classnames(
            'flex h-full w-full items-center justify-center gap-x-1 px-4 text-lg font-bold',
            {
              [activeClasses]: menuItem === activeMenuItem,
              [inactiveClasses]: menuItem !== activeMenuItem,
            }
          )}
          onClick={() => onChange(menuItem)}
        >
          {t(menuItem)}
        </button>
      ))}
    </div>
  );
}

export default PageSubMenu;

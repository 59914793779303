import { useTranslation } from 'react-i18next';
import db from '../index-db';
import { IDType } from '../Models/Enumerations';
import useLogging from './useLogging';
import { useRef } from 'react';

const useAddToSavedItems = () => {
  const { t } = useTranslation();
  const logging = useLogging();
  return useRef(
    async (
      genericId: string | number,
      idType: IDType,
      itemName: string,
      pushToast: any,
      context?: string
    ) => {
      if (genericId) {
        //Check if already saved
        if (
          (
            await db.savedItems
              .where({ IdGeneric: genericId, IdType: idType })
              .toArray()
          ).length === 0
        ) {
          //Get the last saved id
          const lastSavedId = await db.savedItems
            .orderBy('id')
            .last()
            .then((lastItem) => {
              return lastItem?.id ?? 1;
            });
          await db.savedItems
            .add({
              IdGeneric: genericId,
              IdType: idType,
              id: lastSavedId + 1,
              Context: context ?? '',
            })
            .then(() => {
              pushToast({
                message: t(`{{item}} has been saved`, {
                  item: itemName,
                }),
              });
            })
            .catch((error) => {
              logging(
                'error',
                `{{item}} could not be saved`,
                error,
                false,
                null
              );
              pushToast({
                message: t(`{{item}} could not be saved`, {
                  item: itemName,
                }),
                type: 'error',
              });
            });
        } else {
          await db.savedItems
            .where({ IdGeneric: genericId, IdType: idType })
            .delete()
            .then(function () {
              pushToast({
                message: t(`{{item}} has been removed from saved`, {
                  item: itemName,
                }),
              });
            })
            .catch((error) => {
              logging(
                'error',
                `{{item}} could not be removed from saved`,
                error,
                false,
                null
              );
              pushToast({
                message: t(`{{item}} could not be removed from saved`, {
                  item: itemName,
                }),
                type: 'error',
              });
            });
        }
      }
    }
  ).current;
};
export default useAddToSavedItems;

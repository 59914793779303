import db from '../index-db';
import { useLiveQuery } from 'dexie-react-hooks';

const useFieldFeedbackRequestedCount = () => {
  const fieldFeedbackRequestedCount = useLiveQuery(async () => {
    return db.dsaFeedback.where('feedbackState').equals(3).count();
  });

  return fieldFeedbackRequestedCount;
};

export default useFieldFeedbackRequestedCount;

export interface IDSAFeedback {
  id: number;
  raId: number;
  odysseyModuleId: number;
  efcCodeId: number;
  recommendRating: number;
  timeToFix: number;
  comments: string;
  submitter: string;
  submitTime: string;
  feedbackState: number;
  reviewer: string;
  reviewComments: string;
  reviewTime: string;
  enabled: number;
  ratingAccepted: number;
  timeAccepted: number;
  taskActivityNumber: string;
  fieldFeedbackRequester: string;
  fieldFeedbackRequestTime: string;
  fieldFeedbackRequestComments: string;
  level4Reviewer: string;
  level4ReviewTime: string;
  level4ReviewComments: string;
  inProcessByLevel4Reviewer: string;
  inProcessByLevel4ReviewTime: string;
  inProcessByLevel4ReviewComments: string;
  contactName: string;
  correctiveActionRequired: boolean;
  feedbackOutcomeText: string;
}

export class DSAFeedback implements IDSAFeedback {
  id: number;
  raId: number;
  odysseyModuleId: number;
  efcCodeId: number;
  recommendRating: number;
  timeToFix: number;
  comments: string;
  submitter: string;
  submitTime: string;
  feedbackState: number;
  reviewer: string;
  reviewComments: string;
  reviewTime: string;
  enabled: number;
  ratingAccepted: number;
  timeAccepted: number;
  taskActivityNumber: string;
  fieldFeedbackRequester: string;
  fieldFeedbackRequestTime: string;
  fieldFeedbackRequestComments: string;
  level4Reviewer: string;
  level4ReviewTime: string;
  level4ReviewComments: string;
  inProcessByLevel4Reviewer: string;
  inProcessByLevel4ReviewTime: string;
  inProcessByLevel4ReviewComments: string;
  contactName: string;
  correctiveActionRequired: boolean;
  feedbackOutcomeText: string;

  constructor(
    id: number,
    raId: number,
    odysseyModuleId: number,
    efcCodeId: number,
    recommendRating: number,
    timeToFix: number,
    comments: string,
    submitter: string,
    submitTime: string,
    feedbackState: number,
    reviewer: string,
    reviewComments: string,
    reviewTime: string,
    enabled: number,
    ratingAccepted: number,
    timeAccepted: number,
    taskActivityNumber: string,
    fieldFeedbackRequester: string,
    fieldFeedbackRequestTime: string,
    fieldFeedbackRequestComments: string,
    level4Reviewer: string,
    level4ReviewTime: string,
    level4ReviewComments: string,
    inProcessByLevel4Reviewer: string,
    inProcessByLevel4ReviewTime: string,
    inProcessByLevel4ReviewComments: string,
    contactName: string,
    correctiveActionRequired: boolean,
    feedbackOutcomeText: string
  ) {
    this.id = id;
    this.raId = raId;
    this.odysseyModuleId = odysseyModuleId;
    this.efcCodeId = efcCodeId;
    this.recommendRating = recommendRating;
    this.timeToFix = timeToFix;
    this.comments = comments;
    this.submitter = submitter;
    this.submitTime = submitTime;
    this.feedbackState = feedbackState;
    this.reviewer = reviewer;
    this.reviewComments = reviewComments;
    this.reviewTime = reviewTime;
    this.enabled = enabled;
    this.ratingAccepted = ratingAccepted;
    this.timeAccepted = timeAccepted;
    this.taskActivityNumber = taskActivityNumber;
    this.fieldFeedbackRequester = fieldFeedbackRequester;
    this.fieldFeedbackRequestComments = fieldFeedbackRequestComments;
    this.fieldFeedbackRequestTime = fieldFeedbackRequestTime;
    this.level4Reviewer = level4Reviewer;
    this.level4ReviewComments = level4ReviewComments;
    this.level4ReviewTime = level4ReviewTime;
    this.inProcessByLevel4Reviewer = inProcessByLevel4Reviewer;
    this.inProcessByLevel4ReviewComments = inProcessByLevel4ReviewComments;
    this.inProcessByLevel4ReviewTime = inProcessByLevel4ReviewTime;
    this.contactName = contactName;
    this.correctiveActionRequired = correctiveActionRequired;
    this.feedbackOutcomeText = feedbackOutcomeText;
  }
}

export default DSAFeedback;

import { TIcon } from '../../types';
import Icon from '../../components/Icon';
import parse from 'html-react-parser';

interface Props {
  title: string;
  description: string;
  reviewTime: string;
  feedbackOutcomeText?: string;
  feedbackOutcomeIcon?: TIcon;
}

function FeedbackCard({
  title,
  description,
  reviewTime,
  feedbackOutcomeText,
  feedbackOutcomeIcon,
}: Props) {
  let currentDate = new Date(Date.now());
  let timestamp = new Date(reviewTime);
  const diffInTime = currentDate.getTime() - timestamp.getTime();
  const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

  const hours =
    timestamp.getHours() < 10
      ? `0${timestamp.getHours()}`
      : timestamp.getHours().toString();
  const minutes =
    timestamp.getMinutes() < 10
      ? `0${timestamp.getMinutes()}`
      : timestamp.getMinutes().toString();

  const weekMonthDay = timestamp
    .toLocaleDateString(undefined, {
      month: 'short',
      day: 'numeric',
      weekday: 'short',
    })
    .split(',')
    .join(' ');

  return (
    <div className="relative w-full rounded border-2 border-black bg-white">
      <div className="flex flex-row">
        <div className="my-1 ml-2 w-1/2 text-sm font-bold">{title}:</div>
        <div className="my-1 w-1/2 text-sm">
          {diffInDays} {'days ago'} {hours}:{minutes} {weekMonthDay}
        </div>
      </div>
      {description && (
        <div className="min-w-0 flex-row">
          <div className="my-1 ml-2 whitespace-pre-wrap break-words text-sm">
            {parse(description)}
          </div>
        </div>
      )}
      {feedbackOutcomeText && feedbackOutcomeIcon && (
        <div className="flex flex-row">
          <div className="my-1 ml-2 text-sm font-bold">Result:</div>
          <Icon
            is={feedbackOutcomeIcon}
            size="1rem"
            colorClass={'text-yellow'}
            className="my-1 ml-2 text-sm"
          />
          <div className="my-1 ml-2 text-sm">{feedbackOutcomeText}</div>
        </div>
      )}
    </div>
  );
}

export default FeedbackCard;

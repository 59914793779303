export const publicPath = (path: string) => {
  return process.env.PUBLIC_URL + (path.startsWith('/') ? path : `/${path}`);
};

export const delay = (duration: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
};

export const escapeRegExp = (value: string) => {
  return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export function stringToHash(str: string) {
  let hash = 0;

  if (str.length === 0) return hash;

  for (let i = 0; i < str.length; i++) {
    let char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  return hash;
}
export const isToday = (timestamp: number) => {
  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);

  const endOfDay = new Date();
  endOfDay.setHours(23, 59, 59, 999);

  return timestamp >= startOfDay.getTime() && timestamp <= endOfDay.getTime();
};
export const calcCompareTwoSameDay = (d1: string, d2: string) => {
  return (
    new Date(`${d1}`).getTime() - new Date(`${d2}`).getTime() < 1000 * 3600 * 24
  );
};

export const howManyDaysPassed = (dateToCompare: number) => {
  const today = new Date().getTime();
  const previousDate = new Date(dateToCompare).getTime();
  const daysPassed = Math.round((today - previousDate) / (1000 * 60 * 60 * 24));
  return daysPassed;
};

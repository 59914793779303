import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PageSubMenu from '../../../components/PageSubMenu';
import { useTranslation } from 'react-i18next';
import EndPoint from '../../../Models/EndPoint';
import TimelineEvent, { CardRef } from '../TimelineEvent';
import { TimeLineEventType } from '../../../Models/Enumerations';
import { TimelineEvent as TimelineInterface } from '../../../Models/TimeLineEvent';
import timelineModifier from './timeLineModifier';
import useLogging from '../../../hooks/useLogging';
import LogParams from '../../../Models/LogParams';
import { LogType } from '../../../enums/LogType';

interface Props {
  equipmentData: EndPoint;
  activeSubMenuItem: string;
}

function HistoryEquipment(props: Props) {
  const { t } = useTranslation();
  const logging = useLogging();
  const subMenuItems = useMemo(
    () => [
      t('All'),
      t('Tasks'),
      t('Errors'),
      t('Repairs'),
      t('Parts'),
      t('Software'),
    ],
    [t]
  );
  const [activeSubMenu, setActiveSubMenu] = useState<string>(
    t(props.activeSubMenuItem)
  );
  const [displayTimeLineEvents, setDisplayTimeLineEvents] = useState(
    props.equipmentData.timelineEventDetails
  );
  const [persistentTimeLineEvents, setPersistentTimeLineEvents] = useState(
    props.equipmentData.timelineEventDetails
  );
  useEffect(() => {
    setPersistentTimeLineEvents(props.equipmentData.timelineEventDetails);
  }, [props.equipmentData.timelineEventDetails]);
  const [isProcessingDone, setIsProcessingDone] = useState(true);
  const menuChangeHandler = useCallback(
    (subMenuItem: string) => {
      if (persistentTimeLineEvents?.length) {
        let filteredData: TimelineInterface[] | [] = [];
        setIsProcessingDone(false);
        switch (subMenuItem) {
          case subMenuItems[1]:
            filteredData = persistentTimeLineEvents.filter(
              (timeLineEvent) =>
                timeLineEvent.type === TimeLineEventType[TimeLineEventType.Task]
            );
            break;
          case subMenuItems[2]:
            filteredData = persistentTimeLineEvents.filter(
              (timeLineEvent) =>
                timeLineEvent.type ===
                  TimeLineEventType[TimeLineEventType.EFC] ||
                timeLineEvent.type ===
                  TimeLineEventType[TimeLineEventType.ServiceInsight]
            );
            break;
          case subMenuItems[3]:
            filteredData = persistentTimeLineEvents.filter(
              (timeLineEvent) =>
                timeLineEvent.type ===
                TimeLineEventType[TimeLineEventType.Repair]
            );
            break;
          case subMenuItems[4]:
            filteredData = persistentTimeLineEvents.filter(
              (timeLineEvent) =>
                timeLineEvent.type === TimeLineEventType[TimeLineEventType.Part]
            );
            break;
          case subMenuItems[5]:
            filteredData = persistentTimeLineEvents.filter(
              (timeLineEvent) =>
                timeLineEvent.type ===
                TimeLineEventType[TimeLineEventType.SoftwareInventory]
            );
            break;
          default:
            filteredData = persistentTimeLineEvents;
        }
        const calculatedData = timelineModifier(filteredData, subMenuItem, t);

        setDisplayTimeLineEvents(calculatedData);

        setIsProcessingDone(true);
      }
    },
    [persistentTimeLineEvents, subMenuItems, t]
  );
  useEffect(() => {
    menuChangeHandler(activeSubMenu);
  }, [activeSubMenu, menuChangeHandler]);

  const handleActivePageSubMenuItemChange = (subMenuItem: string) => {
    setActiveSubMenu(subMenuItem);
    menuChangeHandler(subMenuItem);
  };

  useEffect(() => {
    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.EquipmentHistory,
      },
      {
        key: LogType.EndpointId,
        value: props.equipmentData.id,
      },
    ];
    logging('info', '', '', false, logParams);
  }, []);

  const [clickedId, setClickedId] = useState<string | undefined>(undefined);
  const cardRefs = useRef<Record<string, CardRef>>({});
  const onTimelineClick = (id: string, type: string) => () => {
    if (
      activeSubMenu === t('All') &&
      type === TimeLineEventType[TimeLineEventType.SoftwareInventory]
    ) {
      handleActivePageSubMenuItemChange(t('Software'));
      setClickedId(id);
    }
  };

  useEffect(() => {
    if (clickedId && isProcessingDone) {
      setTimeout(() => {
        if (!window?.requestAnimationFrame) {
          cardRefs.current[clickedId]?.scrollIntoView({
            block: 'nearest',
          });
        } else {
          requestAnimationFrame(() => {
            cardRefs.current[clickedId]?.scrollIntoView({
              block: 'start',
              behavior: 'smooth',
            });
          });
        }
        setClickedId(undefined);
      }, 200);
    }
  }, [clickedId, isProcessingDone]);
  return (
    <>
      <PageSubMenu
        menuItems={subMenuItems}
        activeMenuItem={activeSubMenu}
        onChange={handleActivePageSubMenuItemChange}
      />
      {props.equipmentData && (
        <div className="flex max-h-full w-full flex-wrap items-stretch gap-2  px-4 pb-2 ">
          {displayTimeLineEvents &&
            displayTimeLineEvents?.length > 0 &&
            displayTimeLineEvents.map((displayEvent) => (
              <div key={displayEvent.id} className=" w-full  lg:w-[36rem]">
                <TimelineEvent
                  timeLineEvent={displayEvent}
                  dataSource={props.equipmentData.dataSource}
                  currentMenu={activeSubMenu}
                  ref={(itemRef: CardRef) => {
                    if (
                      cardRefs.current &&
                      TimeLineEventType[TimeLineEventType.SoftwareInventory]
                    ) {
                      cardRefs.current[displayEvent.id] = itemRef;
                    }
                  }}
                  onTimelineClick={onTimelineClick(
                    displayEvent.id,
                    displayEvent.type
                  )}
                  isClickable={t('Software') !== activeSubMenu}
                />
              </div>
            ))}
        </div>
      )}
    </>
  );
}

export default HistoryEquipment;
